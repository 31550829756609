import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {platformValue} from '../../PlatformUtils';
import {
  COLOR_DARK_4,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY_BOLD,
  FONT_COPY_MEDIUM,
} from '../../Styles/DesignSystem-chancer';

interface IProps {
  value: string | number;
  valueSuffix?: string;
  title: string;
  description?: string;
  reverse?: boolean;
  isMini?: boolean;
}

export const CompSummaryDetail: React.FC<IProps> = (props) => {
  const styles = useMemo(
    () => getStyles(props.reverse ?? false, props.isMini ?? false),
    [props.reverse, props.isMini],
  );

  const value = (
    <View style={styles.valueContainer}>
      <Text
        style={styles.value}
        numberOfLines={platformValue(1, {web: 0})}
        adjustsFontSizeToFit={true}>
        {props.value}
        {props.valueSuffix && (
          <Text style={styles.valueSymbol}>{props.valueSuffix}</Text>
        )}
      </Text>
    </View>
  );
  const copy = (
    <View style={styles.copyContainer}>
      <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
        {props.title}
      </Text>
      {props.description && (
        <Text
          numberOfLines={props.isMini ? 1 : 2}
          ellipsizeMode="tail"
          style={styles.description}>
          {props.description}
        </Text>
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      {props.reverse ? copy : value}
      {props.reverse ? value : copy}
    </View>
  );
};

const getStyles = (reverse: boolean, isMini: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: 24,
    },
    valueContainer: {
      flexGrow: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: isMini ? 32 : 42,
      height: isMini ? 32 : 42,
      borderRadius: 8,
      backgroundColor: COLOR_DARK_4,
    },
    value: {
      color: COLOR_LIGHT,
      fontSize: 18,
      fontFamily: FONT_COPY_BOLD,
    },
    valueSymbol: {
      fontSize: 11,
    },
    copyContainer: {
      flex: 1,
      marginLeft: reverse ? 0 : 8,
      marginRight: reverse ? 8 : 0,
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    title: {
      color: COLOR_LIGHT,
      fontSize: 18,
      fontFamily: FONT_COPY_BOLD,
    },
    description: {
      color: COLOR_GREY_8,
      fontSize: 16,
      fontFamily: FONT_COPY_MEDIUM,
    },
  });
