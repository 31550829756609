import React from 'react';

// Lockable component wrapper, can be used to prefvent re-rendering
export const Lockable = React.memo(
  ({children, isLocked}: {children: React.ReactNode; isLocked: boolean}) => {
    console.log('Rendered Lockable');
    return <>{children}</>;
  },
  (prevProps, nextProps) => {
    // Prevent re-render when locked
    return nextProps.isLocked;
  },
);
