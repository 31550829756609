export enum LocalStorageKey {
  LAST_APP_PROMPT = 'lap',
  DISABLED_LEAGUES = 'disleagues',
  CHAT_READ_DATES = 'crd',
  CHAT_READ_COUNTS = 'crc',
  MUTE = 'mute',
  LAST_UPGRADE_PROMPT = 'lup',
  LAST_NOTIFICATION_PROMPT = 'lnp',
  LAST_NOTIFICATION_RESPONSE = 'lnr',
  VIEWED_ANNOUNCEMENTS = 'va',
}

export type ILocalStorage = {
  [key in LocalStorageKey]?: any;
};
