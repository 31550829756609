import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {getUserDisabledContentBundle} from '../bundle/BundleSelectors';
import {CompStatus} from '../../../interfaces/firestore/FirestoreInterfaces';

// Base selectors here to prevent circular dependencies
const getCompSummariesResult = (state: IAppState) =>
  state.serverApis.firestore.competitions.response;

export const getCompSummariesLoaded = createSelector(
  getCompSummariesResult,
  (result) => result !== null,
);

export const getFilteredCompSummaries = createSelector(
  getCompSummariesResult,
  getUserDisabledContentBundle,
  (comps, disabledLeagues) => {
    if (comps) {
      return comps.filter(
        (comp) =>
          comp.vendor &&
          comp.key &&
          comp.status !== CompStatus.SUSPENDED &&
          !disabledLeagues.includes(comp.league),
      );
    }
    return [];
  },
);
