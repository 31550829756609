import React, {useMemo} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {TUserCompletedMiniLeague} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {Avatar} from '../../Avatar/Avatar';
import {CompDetailsListItem} from '../../CompDetailsList/CompDetailsListItem';
import {Score} from '../../Leaderboard/Score';
import {
  COLOR_GREY_4,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {StartupOverlayScreenContainer} from './StartupOverlayScreenContainer';
import {CompSummaryDetail} from './SummaryDetail';

interface IProps {
  safeAreaTopPadding?: number;
  userImageUrl: string | undefined;
  color: string;
  vendorName: string;
  comps: ICompSummaryAndEntry[];
  multiLeagerboard: TUserCompletedMiniLeague;
  onClose: () => void;
}

export const MiniLeagueContent: React.FC<IProps> = (props) => {
  const {safeAreaTopPadding = 0, multiLeagerboard} = props;

  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );

  const vendorLevelTitle = useMemo(() => {
    return `${props.vendorName} ${multiLeagerboard.levelTitle}`;
  }, [multiLeagerboard.levelTitle, props.vendorName]);

  return (
    <StartupOverlayScreenContainer
      containerStyle={styles.container}
      color={props.color}
      onClose={props.onClose}>
      <ScrollView
        style={styles.scroll}
        contentContainerStyle={styles.scrollContainer}>
        <Avatar
          imageUrl={props.userImageUrl}
          size={96}
          highlightWidth={multiLeagerboard.winner === true ? 3 : 0}
          highlightColor={COLOR_YELLOW}
        />
        <Text style={styles.stars}>
          {'⭐️'.repeat(props.multiLeagerboard.stars)}
        </Text>
        <Text style={styles.title}>You're a {vendorLevelTitle}!</Text>
        <View style={styles.divider} />
        <View style={styles.detailsContainer}>
          <CompSummaryDetail
            key={multiLeagerboard.id}
            value={multiLeagerboard.points}
            title={`You scored ${multiLeagerboard.points} points`}
            description={`${multiLeagerboard.otherPlayersWithSameStars === 0 ? 'No' : `Only ${multiLeagerboard.otherPlayersWithSameStars}`} other player${multiLeagerboard.otherPlayersWithSameStars === 1 ? '' : 's'} made ${vendorLevelTitle}`}
            reverse={true}
            isMini={true}
          />
          {props.comps.map((comp, index) => (
            <React.Fragment key={comp.summary.id}>
              <View style={styles.compAndScoreContainer}>
                <CompDetailsListItem
                  containerStyle={styles.compContainer}
                  comp={comp}
                  colorConfig={getColorConfiguration(comp.summary.primaryColor)}
                  isMini={true}
                  noColorBackground={true}
                />
                <Score score={comp.entry?.verifiedScore ?? 0} size="medium" />
              </View>
              {index < props.comps.length - 1 && (
                <View style={styles.divider} />
              )}
            </React.Fragment>
          ))}
        </View>
      </ScrollView>
    </StartupOverlayScreenContainer>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    container: {
      minHeight: '100%',
    },
    scroll: {
      flex: 1,
      marginTop: 80 + safeAreaTopPadding,
    },
    scrollContainer: {
      flex: 1,
      paddingTop: 56,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
    },
    title: {
      fontFamily: FONT_TITLE,
      textAlign: 'center',
      fontSize: 24,
      color: COLOR_LIGHT,
      marginBottom: 16,
    },
    detailsContainer: {
      flex: 1,
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: 24,
    },
    divider: {width: '100%', backgroundColor: COLOR_GREY_4, height: 1},
    compAndScoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    compContainer: {
      flexShrink: 1,
      paddingLeft: 0,
    },
    stars: {
      fontSize: 24,
      color: COLOR_YELLOW,
      marginTop: 8,
      top: -26,
    },
  });
