import {Static, TSchema} from '@sinclair/typebox';
import {Value} from '@sinclair/typebox/value';
import {
  TClientDocument,
  TFirebaseChallengeGroup,
  TFirebaseChatMessage,
  TFirebaseDefaultGroup,
  TFirebaseGroup,
  TFirebaseLeaders,
  TFirebaseMediaLibraryItem,
  TFirebaseMediaLibraryItemError,
  TFirebaseMediaLibraryItemPending,
  TFirebaseMediaLibraryItemReady,
  TFirebaseMultiGameLeaders,
} from '../interfaces/firestore/FirestoreClientInterfaces';
import {
  ChatMessageType,
  GroupType,
  LeaderboardType,
  MediaLibraryItemStatus,
  TChallengeGroup,
  TChatMessage,
  TDefaultGroup,
  TGroup,
  TLeaders,
  TMediaLibraryItemError,
  TMediaLibraryItemReady,
  TMediaLibraryItemPending,
  TMultiGameLeaders,
  TMediaLibraryItem,
} from '../interfaces/firestore/FirestoreInterfaces';
import {ClientTimestamp} from './ClientTimestamp';

interface IFirebaseProperties {
  data: () => any;
  exists: boolean | (() => boolean);
  id: string;
  ref: {
    path: string;
  };
}

export const convertToClientLeadersByType = (
  isMultiGame: boolean,
  doc: IFirebaseProperties,
): TFirebaseLeaders | TFirebaseMultiGameLeaders => {
  existsOrThrow(doc);
  return isMultiGame
    ? convertClientDocument(TFirebaseMultiGameLeaders, doc)
    : convertClientDocument(TFirebaseLeaders, doc);
};

export const convertToLeadersByType = (
  type: LeaderboardType,
  doc: IFirebaseProperties,
): TLeaders | TMultiGameLeaders => {
  existsOrThrow(doc);
  return type === LeaderboardType.INGAME
    ? convert(TLeaders, doc)
    : convert(TMultiGameLeaders, doc);
};

export const convertToClientGroupByType = (
  doc: IFirebaseProperties,
): TFirebaseGroup => {
  existsOrThrow(doc);
  const type = doc.data().type;
  return type === GroupType.CHALLENGE
    ? convertClientDocument(TFirebaseChallengeGroup, doc)
    : convertClientDocument(TFirebaseDefaultGroup, doc);
};

export const convertToGroupByType = (doc: IFirebaseProperties): TGroup => {
  existsOrThrow(doc);
  const type = doc.data().type;
  return type === GroupType.CHALLENGE
    ? convert(TChallengeGroup, doc)
    : convert(TDefaultGroup, doc);
};

export const convertToClientMediaLibraryItemByType = (
  doc: IFirebaseProperties,
): TFirebaseMediaLibraryItem => {
  existsOrThrow(doc);
  const status = doc.data().status;
  return status === MediaLibraryItemStatus.PENDING
    ? convertClientDocument(TFirebaseMediaLibraryItemPending, doc)
    : status === MediaLibraryItemStatus.ERROR
      ? convertClientDocument(TFirebaseMediaLibraryItemError, doc)
      : convertClientDocument(TFirebaseMediaLibraryItemReady, doc);
};

export const convertToMediaLibraryItemByType = (
  doc: IFirebaseProperties,
): TMediaLibraryItem => {
  existsOrThrow(doc);
  const status = doc.data().status;
  return status === MediaLibraryItemStatus.PENDING
    ? convert(TMediaLibraryItemPending, doc)
    : status === MediaLibraryItemStatus.ERROR
      ? convert(TMediaLibraryItemError, doc)
      : convert(TMediaLibraryItemReady, doc);
};

export const convertToClientFirebaseChatMessage = (
  doc: IFirebaseProperties,
) => {
  existsOrThrow(doc);
  return cleanUnknownChatMessageType(
    convertClientDocument(TFirebaseChatMessage, doc),
  );
};

export const convertToChatMessage = (doc: IFirebaseProperties) => {
  existsOrThrow(doc);
  return cleanUnknownChatMessageType(convert(TChatMessage, doc));
};

export const convert = <T extends TSchema>(
  schema: T,
  doc: IFirebaseProperties,
  props?: Partial<Static<T>>,
) => {
  existsOrThrow(doc);
  return Value.Cast(schema, {...doc.data(), ...props});
};

export const convertClientDocument = <T extends TSchema>(
  schema: T,
  doc: IFirebaseProperties,
  props?: Partial<Static<T> & TClientDocument>,
) => {
  existsOrThrow(doc);
  return convert(schema, doc, {
    id: doc.id,
    path: doc.ref.path,
    ...props,
  });
};

const existsOrThrow = (doc: IFirebaseProperties) => {
  if (typeof doc.exists === 'boolean') {
    if (!doc.exists) {
      throw new Error(`Document '${doc.ref.path}' does not exist`);
    }
  } else {
    if (!doc.exists()) {
      throw new Error(`Document '${doc.ref.path}' does not exist`);
    }
  }
};

export const processSerialisedTimestamps = <T>(doc: T): T => {
  for (const prop in doc) {
    const value = doc[prop];
    if (isObject(value)) {
      if (
        Object.prototype.hasOwnProperty.call(value, '_seconds') &&
        Object.prototype.hasOwnProperty.call(value, '_nanoseconds')
      ) {
        (doc[prop] as any) = new ClientTimestamp(
          (value as any)['_seconds'],
          (value as any)['_nanoseconds'],
        );
      } else if (
        Object.prototype.hasOwnProperty.call(value, 'seconds') &&
        Object.prototype.hasOwnProperty.call(value, 'nanoseconds')
      ) {
        (doc[prop] as any) = new ClientTimestamp(
          (value as any)['seconds'],
          (value as any)['nanoseconds'],
        );
      } else {
        (doc[prop] as any) = processSerialisedTimestamps(value as any);
      }
    }
  }
  return doc;
};

const isObject = (obj: any): obj is object => {
  const type = typeof obj;
  return type === 'object' && !!obj && !Array.isArray(obj);
};

export const getLargerAuthPhotoUrl = <T extends string | null>(
  photoUrl: T,
): T => {
  //workaround to get higer res profile picture
  let result = photoUrl;
  if (result !== null && result !== undefined) {
    if ((result as string).includes('google')) {
      result = result.replace('s96-c', 's400-c') as T;
    }
    if ((result as string).includes('graph.facebook.com/')) {
      result = `${result}?height=400` as T;
    }
  }
  return result;
};

const cleanUnknownChatMessageType = <T extends TChatMessage>(m: T) => {
  return m.type === ChatMessageType.UNKNOWN
    ? m.userId
      ? {...m, type: ChatMessageType.DEFAULT}
      : {...m, type: ChatMessageType.SYSTEM_DEFAULT}
    : m;
};
