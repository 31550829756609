import React, {ComponentType, useCallback, useMemo, useState} from 'react';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {TFirebaseChallengeGroup} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TMediaEntry} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {IStartupOverlayProps} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {createCompetitionVendor} from '@chancer/common/lib/utils/CompetitionUtils';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {StyleSheet, View} from 'react-native';
import {ChallengeSetupHeader} from '../../Challenges/ChallengeSetupHeader';
import {platformValue} from '../../PlatformUtils';
import {COLOR_DARK} from '../../Styles/DesignSystem-chancer';
import {AchievementContent} from './AchievementContent';
import {AnnouncementContent} from './AnnouncementContent';
import {ChallengeSummaryContent} from './ChallengeSummaryContent';
import {CompSummaryContent} from './CompSummaryContent';
import {MiniLeagueContent} from './MiniLeagueContent';
import {StoryProgressBar} from './StoryProgressBar';

interface IProps extends IStartupOverlayProps {
  safeAreaTopPadding: number;
  AchievementIconComponent: ComponentType<{achievement: AchievementType}>;
  ChallengeHightlight?: ComponentType<{}>;
  MediaComponent: ComponentType<{media: TMediaEntry}>;
  appPlatform: AppPlatform;
  webUrl: string;
  compSummaries: {[compId: string]: ICompSummaryAndEntry};
  userChallenges?: TFirebaseChallengeGroup[];
  getUserById: ReturnType<typeof getUserByIdStream>;
  onChallengeSelected?: (challenge: TFirebaseChallengeGroup) => void;
  onClose: () => void;
}

export const StartupOverlayContent: React.FC<IProps> = (props) => {
  const {onClose} = props;

  const [screenIndex, setScreenIndex] = useState(0);

  // Add const for each type to assist with typesafe access
  const screens = [
    ...props.achievements.flatMap((c) =>
      c.achievements
        .map((a, idx) =>
          a > 0
            ? {
                type: 'achievement' as const,
                data: c,
                achievement: idx,
                comp: props.compSummaries[c.compId],
              }
            : undefined,
        )
        .filter((a) => a !== undefined),
    ),
    ...props.comps.map((c) => ({
      type: 'competition' as const,
      data: c,
      comp: props.compSummaries[c.compId],
    })),
    ...props.challenges.map((c) => ({
      type: 'challenge' as const,
      data: c,
      comp: props.compSummaries[c.compId],
    })),
    ...props.miniLeagues.map((m) => ({
      type: 'mini-league' as const,
      data: m,
      comp: Object.values(props.compSummaries).find((c) =>
        m.compIds.includes(c.summary.id),
      ),
      comps: m.compIds.flatMap((id) =>
        props.compSummaries[id] ? [props.compSummaries[id]] : [],
      ),
    })),
    ...(props.announcement
      ? [{type: 'announcement' as const, data: props.announcement}]
      : []),
  ].filter((s) => s.type === 'announcement' || s.comp !== undefined);

  const styles = useMemo(
    () => getStyles(props.safeAreaTopPadding),
    [props.safeAreaTopPadding],
  );
  const nextScreen = useCallback(() => {
    if (screenIndex === screens.length - 1) {
      onClose();
    } else {
      setScreenIndex(screenIndex + 1);
    }
  }, [screenIndex, screens.length, onClose]);

  const onNextScreen = useCallback(() => {
    nextScreen();
  }, [nextScreen]);

  const getChallenge = useCallback(
    (challengeId: string) =>
      props.userChallenges?.find((c) => c.id === challengeId),
    [props.userChallenges],
  );

  const screenDetails = screens[screenIndex];
  let screenComponent = null;
  let backGroundColor = COLOR_DARK;
  let compName = '';
  let compSubTitle = '';
  let compImage = `${props.webUrl}/icon-192.png`;
  if (screenDetails.type === 'announcement') {
    backGroundColor = screenDetails.data.color;
    screenComponent = (
      <AnnouncementContent
        safeAreaTopPadding={props.safeAreaTopPadding}
        color={backGroundColor}
        title={screenDetails.data.title}
        description={screenDetails.data.media.caption ?? ''}
        media={<props.MediaComponent media={screenDetails.data.media} />}
        onClose={onNextScreen}
      />
    );
  } else {
    const vendor = createCompetitionVendor(screenDetails.comp!.summary);
    compName = screenDetails.comp!.summary.shortName;
    compSubTitle = screenDetails.comp!.summary.name;
    compImage = screenDetails.comp!.summary.logo.image?.url ?? '';
    backGroundColor = screenDetails.comp!.summary.primaryColor;
    if (screenDetails.type === 'achievement') {
      screenComponent = (
        <AchievementContent
          key={`achievement_${screenDetails.comp.summary.id}`}
          customIcon={
            <props.AchievementIconComponent
              achievement={screenDetails.achievement}
            />
          }
          safeAreaTopPadding={props.safeAreaTopPadding}
          color={backGroundColor}
          score={screenDetails.data.score}
          achievement={screenDetails.achievement}
          compName={vendor.name}
          vendor={vendor.vendor}
          onClose={onNextScreen}
        />
      );
    } else if (screenDetails.type === 'competition') {
      screenComponent = (
        <CompSummaryContent
          key={`competition_${screenDetails.comp.summary.id}`}
          safeAreaTopPadding={props.safeAreaTopPadding}
          color={backGroundColor}
          compName={screenDetails.comp.summary.shortName}
          logoUrl={screenDetails.comp.summary.logo.image?.url}
          userImageUrl={props.user.media?.image?.url}
          leaderboards={screenDetails.data.leaderboards}
          onClose={onNextScreen}
        />
      );
    } else if (screenDetails.type === 'challenge') {
      screenComponent = (
        <ChallengeSummaryContent
          key={screenDetails.data.challengeId}
          ChallengeHightlight={props.ChallengeHightlight}
          safeAreaTopPadding={props.safeAreaTopPadding}
          color={backGroundColor}
          currentUser={props.user}
          challengeSummary={screenDetails.data}
          challenge={getChallenge(screenDetails.data.challengeId)}
          getUserById={props.getUserById}
          onClose={onNextScreen}
          onChallengeSelected={props.onChallengeSelected}
        />
      );
    } else if (screenDetails.type === 'mini-league') {
      screenComponent = (
        <MiniLeagueContent
          key={screenDetails.data.id}
          safeAreaTopPadding={props.safeAreaTopPadding}
          color={backGroundColor}
          userImageUrl={props.user.media?.image?.url}
          comps={screenDetails.comps}
          vendorName={vendor.name}
          multiLeagerboard={screenDetails.data}
          onClose={onNextScreen}
        />
      );
    }
  }

  return (
    <View style={styles.container}>
      {screenComponent}
      <View style={styles.header}>
        <StoryProgressBar
          style={styles.progressBar}
          backgroundColor={backGroundColor}
          screenIndex={screenIndex}
          totalScreens={screens.length}
          onNextScreen={onNextScreen}
        />
        <ChallengeSetupHeader
          imageUrl={compImage}
          title={compName}
          subtitle={compSubTitle}
          appPlatform={props.appPlatform}
          safeAreaTopPadding={0}
        />
      </View>
    </View>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    container: {flex: 1, ...platformValue({}, {web: {height: '100%'}})},
    header: {
      position: 'absolute',
      top: 16 + safeAreaTopPadding,
      left: 16,
      right: 16,
    },
    progressBar: {
      marginBottom: 8,
    },
  });
