import {ReactElement} from 'react';
import {AppAction, IEnterCompPayload} from '../../app/AppMessage';
import {IAction} from '../../core/actions/Actions';
import {
  TFirebaseAnnouncement,
  TFirebaseChallengeGroup,
  TFirebaseCompCounts,
  TFirebaseGroup,
  TFirebaseLeaderboard,
  TFirebaseQuestion,
  TFirebaseUser,
} from '../firestore/FirestoreClientInterfaces';
import {
  TUserCompletedAchievement,
  TUserCompletedChallenge,
  TUserCompletedComp,
  TUserCompletedMiniLeague,
} from '../firestore/FirestoreInterfaces';

export interface IOverlay {
  type: OverlayType;
  transitionDuration: number;
}

export interface IOverlayViewProps {
  displayState: OverlayDisplayState;
  onClose: () => void;
  onTransitionOutComplete: () => void;
}

export interface ISimpleMessageOverlayProps extends IOverlay {
  headerComponent?: ReactElement;
  footerComponent?: ReactElement;
  title: string;
  message: string;
  closeButtonText?: string;
  onCloseRoutine?: () => void;
  actionButtonText?: string;
  onActionRoutine?: () => void;
  dismissable?: boolean;
}
export interface ISimpleMessageFromAppOverlayProps extends IOverlay {
  title: string;
  message: string;
  closeButtonText?: string;
  onCloseRoutine?: AppAction;
  actionButtonText?: string;
  onActionRoutine?: AppAction;
  dismissable: boolean;
}

export interface IUploadImageOverlayProps extends IOverlay {
  uploadId: string;
  targetDimensions: {
    width: number;
    height: number;
    crop: boolean;
    isCircleCrop?: boolean;
  };
  remoteFolderPath: string;
  onSuccessAction?: (remoteUrl: string) => IAction<any>;
  onDeleteAction: () => IAction<any>;
}

export interface ILeaderboardDetailsOverlayProps extends IOverlay {
  leaderboardConfig: TFirebaseLeaderboard;
}

export interface IEnterCompOverlayProps extends IOverlay {
  entry: IEnterCompPayload;
}

export interface IGroupSettingsOverlayProps extends IOverlay {
  group: TFirebaseGroup;
  onDeleteGroup: () => void;
}

export interface IPlayerProfileOverlayProps extends IOverlay {
  user: TFirebaseUser;
  vendor: string;
  compId?: string;
  questions?: TFirebaseQuestion[];
  counts?: TFirebaseCompCounts;
}

export interface ICompareOverlayProps extends IOverlay {
  userA: TFirebaseUser;
  userB: TFirebaseUser;
  vendor: string;
  compId?: string;
  questions?: TFirebaseQuestion[];
  counts?: TFirebaseCompCounts;
}

export interface IChallengeOverlayProps extends IOverlay {
  group: TFirebaseChallengeGroup;
  tabIndex: number;
}

export interface IGetNativeAppGroupOverlayProps extends IOverlay {
  groupId: string;
}

export interface IGetUpgradePromptOverlayProps extends IOverlay {
  daysRemaining: number;
}

export interface INotificationPromptOverlayProps extends IOverlay {
  settingsPrompt: boolean;
}

export interface IStartupOverlayProps extends IOverlay {
  user: TFirebaseUser;
  achievements: TUserCompletedAchievement[];
  comps: TUserCompletedComp[];
  challenges: TUserCompletedChallenge[];
  miniLeagues: TUserCompletedMiniLeague[];
  announcement: TFirebaseAnnouncement | null;
}

export enum OverlayType {
  ERROR = 0,
  SIMPLE_MESSAGE = 1,
  GET_NATIVE_APP_LEADERBOARDS = 2,
  // ACCOUNT_LOGIN = 3,
  SHARE = 4,
  // ACHIEVEMENT = 6,
  NATIVE_APP_UPDATE_REQUIRED = 7,
  LEADERBOARD_DETAILS = 8,
  GET_NATIVE_APP_PROMPT = 9,
  LOADING = 10,
  ENTER_COMP = 11,
  GROUP_SETTINGS = 12,
  UPLOAD_PROFILE_IMAGE = 5, // Retained so that old versions of the app can still open the overlay
  UPLOAD_IMAGE = 13,
  // COMP_SUMMARY = 14,
  GET_NATIVE_APP_GROUP = 15,
  PLAYER_PROFILE = 16,
  COMPARE = 17,
  GET_UPGRADE_PROMPT = 18,
  GET_NATIVE_APP_MISSED_QUESTION = 19,
  GET_NATIVE_APP_CHALLENGE = 20,
  CHALLENGE_DETAILS = 21,
  NOTIFICATIONS_PROMPT = 22,
  STARTUP = 23,
}

export enum OverlayDisplayState {
  DISPLAYED = 0,
  NONE = 1,
  TRANSITION_OUT = 2,
}
