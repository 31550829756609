import React, {ReactElement, useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  AppPlatform,
  EnterCompContentType,
  EnterCompStatus,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {QuestionStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {PrimaryButton} from '../Button/PrimaryButton';
import {SecondaryButton} from '../Button/SecondaryButton';
import {CompDetailsListItem} from '../CompDetailsList/CompDetailsListItem';
import {MiniLeagueSummaryScore} from '../MiniLeague/MiniLeagueSummaryScore';
import {
  COLOR_LIGHT,
  FONT_COPY,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {FormattedDescription} from './FormattedDescription';

interface IProps {
  type: EnterCompContentType;
  appPlatform: AppPlatform;
  primaryColor?: string;
  leaderboardComps: ICompSummaryAndEntry[];
  compEntryStatus: EnterCompStatus;
  questions: TFirebaseQuestion[] | null;
  mediaComponent?: ReactElement;
  currentUser: TFirebaseUser | null;
  gameUrl: string;
  cdnUrl: string;
  vendorImageUrl?: string;
  isEntryWithMissedQuestions: boolean;
  vendorName?: string;
  onScreenAnalytics: (
    type: string,
    promo: string,
    isTempEntry: boolean,
    missedQuestions: boolean,
  ) => void;
  onRetryEntry: () => void;
  onHome: () => void;
  onCompetitionSelected: (comp: ICompSummaryAndEntry) => void;
}

export const EnterMiniLeagueCompContent: React.FC<IProps> = (props) => {
  const {
    type,
    appPlatform,
    compEntryStatus,
    currentUser,
    isEntryWithMissedQuestions,
    onScreenAnalytics,
  } = props;
  const [showError, setShowError] = React.useState(false);
  const colorConfig = getColorConfiguration(props.primaryColor);

  const isTemporary =
    appPlatform === AppPlatform.WEB && currentUser?.temporary === true;

  useEffect(() => {
    if (compEntryStatus === EnterCompStatus.ERROR) {
      setShowError(true);
    } else if (compEntryStatus === EnterCompStatus.COMPLETE) {
      setShowError(false);
    }
  }, [compEntryStatus]);

  useEffect(() => {
    onScreenAnalytics(
      type,
      'mini-league',
      isTemporary,
      isEntryWithMissedQuestions,
    );
  }, [type, isTemporary, isEntryWithMissedQuestions, onScreenAnalytics]);

  const compIsStillOpenForEntry = props.questions?.some(
    (q) =>
      q.status === QuestionStatus.OPEN ||
      q.status === QuestionStatus.OPEN_CHANGEABLE,
  );

  const numRemainingToEnter = props.leaderboardComps.filter(
    (l) => l.entry === null,
  ).length;

  const title =
    compEntryStatus === EnterCompStatus.ERROR
      ? 'Whoops'
      : !compIsStillOpenForEntry
        ? 'Entries are closed'
        : numRemainingToEnter > 0
          ? `${numRemainingToEnter} of ${props.leaderboardComps.length} REMAINING!`
          : 'You’re all up to date!';

  const description =
    compEntryStatus === EnterCompStatus.ERROR
      ? 'We have had a problem entering you in the competition.'
      : !compIsStillOpenForEntry
        ? 'Sorry, this competition is now in play. Entries are closed but look out for our next game and get in early!'
        : numRemainingToEnter > 0
          ? 'Make your picks on the remaining games.'
          : '';

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {showError ? (
        <>
          <FormattedDescription
            style={styles.description}
            description={description}
          />
          <View style={styles.spacer} />
          <PrimaryButton
            style={styles.button}
            label="Try Again"
            onPress={props.onRetryEntry}
          />
        </>
      ) : (
        <>
          <Text style={styles.description}>{description}</Text>
          {props.leaderboardComps.map((comp) => (
            <CompDetailsListItem
              containerStyle={styles.listItemContainer}
              key={comp.summary.id}
              colorConfig={colorConfig}
              comp={comp}
              onPress={props.onCompetitionSelected}>
              <MiniLeagueSummaryScore comp={comp} />
            </CompDetailsListItem>
          ))}
          <SecondaryButton
            style={styles.secondaryButton}
            size="medium"
            label="Home"
            onPress={props.onHome}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
    overflow: 'hidden',
  },
  title: {
    fontFamily: FONT_SUB_TITLE,
    fontSize: 18,
    color: COLOR_LIGHT,
    marginTop: 4,
    pointerEvents: 'none',
  },
  description: {
    minWidth: '100%',
    marginTop: 16,
    marginBottom: 16,
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_LIGHT,
    pointerEvents: 'none',
    textAlign: 'center',
  },
  listItemContainer: {
    borderRadius: 8,
    marginTop: 4,
  },
  spacer: {
    marginVertical: 16,
    alignSelf: 'stretch',
    height: 0,
  },
  button: {
    width: '100%',
  },
  secondaryButton: {
    opacity: 0.8,
    marginTop: 6,
    width: '100%',
    backgroundColor: 'transparent',
  },
});
