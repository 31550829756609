import {IAuthUserInfo} from '../../interfaces/auth/AuthInterfaces';
import {ILocalAnswersForComp} from '../../interfaces/client/ClientInterfaces';
import {
  TFirebaseAnnouncement,
  TFirebaseChatMessage,
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompStatus,
  TFirebaseCompSummary,
  TFirebaseGroup,
  TFirebaseGroupCounts,
  TFirebaseGroupPrivate,
  TFirebaseInboxMessage,
  TFirebaseLeaderboard,
  TFirebaseLeaders,
  TFirebaseMultiGameLeaders,
  TFirebaseNewsItem,
  TFirebaseQuestion,
  TFirebaseTempEntry,
  TFirebaseUser,
  TFirebaseUserFollowList,
  TFirebaseUserPrivateProfile,
} from '../../interfaces/firestore/FirestoreClientInterfaces';
import {
  TChatMessage,
  TGroup,
  TTempEntry,
  TUser,
  TUserPrivateProfile,
} from '../../interfaces/firestore/FirestoreInterfaces';
import {
  IOverlay,
  OverlayDisplayState,
} from '../../interfaces/overlay/OverlayInterfaces';
import {IConfig} from '../config/Config';
import {IRemoteConfig} from '../config/RemoteConfig';
import {ILocalStorage} from './model/LocalStorage';

export const defaultAppState = (
  config: IConfig,
  remoteConfig: IRemoteConfig,
): IAppState => ({
  auth: {
    isLogginIn: true,
    isNewUser: false,
    user: null,
    temporaryUserScore: 0,
  },
  config: config,
  remoteConfig: remoteConfig,
  localAnswers: {
    loaded: false,
    values: {},
  },
  localStorage: {},
  overlays: {
    current: null,
    currentOverlayState: OverlayDisplayState.NONE,
    queue: [],
  },
  serverApis: {
    firestore: {
      competitions: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      competition: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      competitionCounts: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      competitionVips: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      competitionStatus: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      competitionChat: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      sendChat: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      enterCompetition: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      leaderboardConfiguration: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      leaders: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      questions: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      user: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      updateUser: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      userPrivate: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      updateUserPrivate: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      userFollowing: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      userMessages: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      tempEntry: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      getTempEntry: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      userEntries: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      userGroups: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      group: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      addGroup: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      editGroup: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupChat: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupCounts: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupPrivates: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupQuestions: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupCompCounts: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      groupLiveLeaderboards: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      sendGroupChat: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorQuestions: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorCompCounts: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorLiveLeaderboards: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorMiniLeagueLeaderboard: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorMiniLeagueLeaderboardConfig: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      vendorNews: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      announcements: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      news: {
        error: null,
        isInvalidated: false,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
    },
    mediaUploads: {},
  },
});

export interface IAppState {
  auth: IAuth;
  config: IConfig;
  remoteConfig: IRemoteConfig;
  localAnswers: ILocalAnswers;
  localStorage: ILocalStorage;
  overlays: IOverlays;
  serverApis: {
    mediaUploads: IMediaUploadsApi;
    firestore: IFirestoreServerApi;
  };
}

export interface IAuth {
  isLogginIn: boolean;
  isNewUser: boolean;
  user: IAuthUserInfo | null;
  temporaryUserScore: number;
}

export interface ILocalAnswers {
  loaded: boolean;
  values: ILocalAnswersState;
}

export interface ILocalAnswersState {
  [competitionId: string]: ILocalAnswersForComp;
}

export interface IMediaUploadsApi {
  [uploadId: string]: IApiDataWithProgress<
    IMediaUploadRequest,
    IMediaUploadResponse,
    IMediaUploadError
  >;
}

export interface IMediaUploadRequest {
  uploadId: string;
  localPath: string;
  remotePath: string;
}

export interface IMediaUploadResponse {
  uploadId: string;
  url: string;
}

export interface IMediaUploadError {
  uploadId: string;
  error: string;
}

export interface IDynamicLink {
  [url: string]: string | undefined;
}

export interface IFirestoreServerApi {
  enterCompetition: IApiData<string, string, string>;
  competitions: IApiData<null, TFirebaseCompSummary[], string>;
  competition: IApiData<string, TFirebaseComp, string>;
  questions: IApiData<string, TFirebaseQuestion[], string>;
  user: IApiData<string, TFirebaseUser, string>;
  updateUser: IApiData<TUser, null, string>;
  userPrivate: IApiData<string, TFirebaseUserPrivateProfile, string>;
  updateUserPrivate: IApiData<TUserPrivateProfile, null, string>;
  userFollowing: IApiData<string, TFirebaseUserFollowList, string>;
  userEntries: IApiData<string, TFirebaseCompEntry[], string>;
  userMessages: IApiData<string, TFirebaseInboxMessage[], string>;
  userGroups: IApiData<string, TFirebaseGroup[], string>;
  leaderboardConfiguration: IApiData<string, TFirebaseLeaderboard, string>;
  leaders: IApiData<
    string,
    TFirebaseLeaders | TFirebaseMultiGameLeaders,
    string
  >;
  competitionCounts: IApiData<string, TFirebaseCompCounts, string>;
  competitionVips: IApiData<string, TFirebaseUser[], string>;
  competitionStatus: IApiData<string, TFirebaseCompStatus, string>;
  competitionChat: IApiData<string, TFirebaseChatMessage[], string>;
  sendChat: IApiData<TChatMessage, null, string>;
  tempEntry: IApiData<
    TTempEntry,
    {competitionId: string; tempId: string},
    string
  >;
  getTempEntry: IApiData<string, IFirebaseTempEntryWithComp, string>;
  group: IApiData<string, TFirebaseGroup, string>;
  addGroup: IApiData<TGroup, TFirebaseGroup, string>;
  editGroup: IApiData<Partial<TGroup>, string, string>;
  groupChat: IApiData<string, TFirebaseChatMessage[], string>;
  groupCounts: IApiData<
    string,
    {[groupId: string]: TFirebaseGroupCounts},
    string
  >;
  groupPrivates: IApiData<
    string[],
    {[compId: string]: TFirebaseGroupPrivate},
    string
  >;
  groupQuestions: IApiData<
    string[],
    {[compId: string]: TFirebaseQuestion[]},
    string
  >;
  groupCompCounts: IApiData<
    string[],
    {[compId: string]: TFirebaseCompCounts},
    string
  >;
  groupLiveLeaderboards: IApiData<
    string[],
    {[compId: string]: TFirebaseLeaders},
    string
  >;
  vendorQuestions: IApiData<
    string[],
    {[compId: string]: TFirebaseQuestion[]},
    string
  >;
  vendorCompCounts: IApiData<
    string[],
    {[compId: string]: TFirebaseCompCounts},
    string
  >;
  vendorLiveLeaderboards: IApiData<
    string[],
    {[compId: string]: TFirebaseLeaders},
    string
  >;
  vendorMiniLeagueLeaderboard: IApiData<
    string,
    TFirebaseMultiGameLeaders,
    string
  >;
  vendorMiniLeagueLeaderboardConfig: IApiData<
    string,
    TFirebaseLeaderboard,
    string
  >;
  vendorNews: IApiData<string, TFirebaseNewsItem[], string>;
  sendGroupChat: IApiData<TChatMessage, null, string>;
  announcements: IApiData<null, TFirebaseAnnouncement[], string>;
  news: IApiData<null, TFirebaseNewsItem[], string>;
}

export interface IFirebaseTempEntryWithComp extends TFirebaseTempEntry {
  competitionId: string;
}

export interface IApiData<R, T, E> {
  lastUpdated: Date | null;
  isUpdating: boolean;
  isInvalidated: boolean;
  request: R | null;
  response: T | null;
  error: E | null;
}

export interface IApiDataWithProgress<R, T, E> extends IApiData<R, T, E> {
  progress: number;
}

export interface IOverlays {
  current: IOverlay | null;
  queue: IOverlay[];
  currentOverlayState: OverlayDisplayState;
}
