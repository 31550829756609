import {Epic, ofType} from 'redux-observable';
import {filter, map} from 'rxjs/operators';

import {ActionType, IAction} from '../../actions/Actions';
import {
  setAnnouncementViewed,
  setLocalStorage,
} from '../../actions/localStorage/LocalStorageActions';
import {getLocalStorage} from '../../selectors/localStorage/LocalStorageSelectors';
import {IAppState} from '../../state/AppState';
import {LocalStorageKey} from '../../state/model/LocalStorage';
import {announcementsResponse} from '../../actions/firestore/FirestoreActions';
import {TFirebaseAnnouncement} from '../../../interfaces/firestore/FirestoreClientInterfaces';

export const cleanLocalAnnouncementViewCountEpic: Epic<
  ReturnType<typeof announcementsResponse>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.ANNOUNCEMENTS_RESPONSE),
    filter(
      () =>
        getLocalStorage(state$.value)[LocalStorageKey.VIEWED_ANNOUNCEMENTS] !==
        undefined,
    ),
    map((action) =>
      cleanedUpStoredViewCounts(
        action.payload,
        getLocalStorage(state$.value)[LocalStorageKey.VIEWED_ANNOUNCEMENTS],
      ),
    ),
    map((viewedCount: {[announcementId: string]: number}) =>
      setLocalStorage(LocalStorageKey.VIEWED_ANNOUNCEMENTS, viewedCount),
    ),
  );

const cleanedUpStoredViewCounts = (
  liveAnnouncements: TFirebaseAnnouncement[],
  storedAnnouncementViews: {[groupId: string]: number},
): {[announcementId: string]: number} => {
  const liveIds = liveAnnouncements.map((a) => a.id);
  const cleanedViewedCounts: {[announcementId: string]: number} = {};
  Object.keys(storedAnnouncementViews).forEach((id) => {
    if (liveIds.includes(id)) {
      cleanedViewedCounts[id] = storedAnnouncementViews[id];
    }
  });
  return cleanedViewedCounts;
};

export const setLocalAnnouncementViewCountEpic: Epic<
  ReturnType<typeof setAnnouncementViewed>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCAL_ANNOUNCEMENT_VIEWED),
    map((action) =>
      convertToLocalViewedCounts(
        action.payload,
        getLocalStorage(state$.value)[LocalStorageKey.VIEWED_ANNOUNCEMENTS] ??
          {},
      ),
    ),
    map((viewedCount: {[announcementId: string]: number}) =>
      setLocalStorage(LocalStorageKey.VIEWED_ANNOUNCEMENTS, viewedCount),
    ),
  );

const convertToLocalViewedCounts = (
  id: string,
  storedAnnouncementViews: {[groupId: string]: number},
): {[announcementId: string]: number} => ({
  ...storedAnnouncementViews,
  ...{
    [id]: storedAnnouncementViews[id] ? storedAnnouncementViews[id] + 1 : 1,
  },
});
