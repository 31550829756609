import React from 'react';

import {StyleSheet, Text, View} from 'react-native';
import {COLOR_LIGHT, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IFormattedDescriptionProps {
  style?: CompositedViewStyle;
  description: string;
}

export const FormattedDescription: React.FC<IFormattedDescriptionProps> = (
  props,
) => {
  const {description} = props;
  console.log('description', description);
  const formattedDescription = description.split('\n').map((item, key, arr) => {
    return (
      <Text
        key={key}
        style={
          key + 1 === arr.length
            ? styles.copy
            : [styles.copy, styles.copyPadding]
        }>
        {item}
      </Text>
    );
  });
  return (
    <View style={[styles.container, props.style]}>{formattedDescription}</View>
  );
};

const styles = StyleSheet.create({
  container: {},
  copy: {
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_LIGHT,
  },
  copyPadding: {marginBottom: 8},
});
