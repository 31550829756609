import {
  TFirebaseAnnouncement,
  TFirebaseChatMessage,
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompStatus,
  TFirebaseCompSummary,
  TFirebaseGroup,
  TFirebaseGroupCounts,
  TFirebaseGroupPrivate,
  TFirebaseInboxMessage,
  TFirebaseLeaderboard,
  TFirebaseLeaders,
  TFirebaseMultiGameLeaders,
  TFirebaseNewsItem,
  TFirebaseQuestion,
  TFirebaseUser,
  TFirebaseUserFollowList,
  TFirebaseUserPrivateProfile,
} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {
  TChatMessage,
  TChatMessagePayload,
  TGroup,
  TTempEntry,
  TUser,
  TUserPrivateProfile,
} from '../../../interfaces/firestore/FirestoreInterfaces';

import {processErrorToString} from '../../../utils/ErrorUtils';

import {IEnterCompPayload} from '../../../app/AppMessage';
import {IFirebaseTempEntryWithComp} from '../../state/AppState';
import {ActionType, IAction} from '../Actions';

export const onCompetitionsRequest = (): IAction<null> => ({
  type: ActionType.COMPETITIONS_REQUEST,
  payload: null,
});

export const onCompetitionsResponse = (
  competitions: TFirebaseCompSummary[],
): IAction<TFirebaseCompSummary[]> => ({
  type: ActionType.COMPETITIONS_RESPONSE,
  payload: competitions,
});

export const onCompetitionsError = (error: any): IAction<string> => ({
  type: ActionType.COMPETITIONS_ERROR,
  payload: processErrorToString(error),
});

export const resetCompetition = (): IAction<null> => ({
  type: ActionType.RESET_COMPETITION,
  payload: null,
});

export const fetchCompetition = (
  compParams: string | string[],
): IAction<string | string[]> => ({
  type: ActionType.FETCH_COMPETITION,
  payload: compParams,
});

export const onCompetitionRequest = (
  compParams: string | string[],
): IAction<string | string[]> => ({
  type: ActionType.COMPETITION_REQUEST,
  payload: compParams,
});

export const onCompetitionResponse = (
  competition: TFirebaseComp,
): IAction<TFirebaseComp> => ({
  type: ActionType.COMPETITION_RESPONSE,
  payload: competition,
});

export const onCompetitionError = (error: any): IAction<string> => ({
  type: ActionType.COMPETITION_ERROR,
  payload: processErrorToString(error),
});

export const onQuestionsRequest = (path: string): IAction<string> => ({
  type: ActionType.QUESTIONS_REQUEST,
  payload: path,
});

export const onQuestionsResponse = (
  questions: TFirebaseQuestion[],
): IAction<TFirebaseQuestion[]> => ({
  type: ActionType.QUESTIONS_RESPONSE,
  payload: questions,
});

export const onQuestionsError = (error: any): IAction<string> => ({
  type: ActionType.QUESTIONS_ERROR,
  payload: processErrorToString(error),
});

export const fetchUser = (): IAction<null> => ({
  type: ActionType.FETCH_USER,
  payload: null,
});

export const userRequest = (uid: string): IAction<string> => ({
  type: ActionType.USER_REQUEST,
  payload: uid,
});

export const userResponse = (user: TFirebaseUser): IAction<TFirebaseUser> => ({
  type: ActionType.USER_RESPONSE,
  payload: user,
});

export const userError = (err: any): IAction<string> => ({
  type: ActionType.USER_ERROR,
  payload: processErrorToString(err),
});

export const fetchDeleteUser = (): IAction<null> => ({
  type: ActionType.FETCH_DELETE_USER,
  payload: null,
});

export const fetchUpdateUser = (
  updatedDetails: Partial<TUser>,
): IAction<Partial<TUser>> => ({
  type: ActionType.FETCH_UPDATE_USER,
  payload: updatedDetails,
});

export const updateUserRequest = (
  user: Partial<TUser>,
): IAction<Partial<TUser>> => ({
  type: ActionType.UPDATE_USER_REQUEST,
  payload: user,
});

export const updateUserResponse = (): IAction<null> => ({
  type: ActionType.UPDATE_USER_RESPONSE,
  payload: null,
});

export const updateUserError = (err: any): IAction<string> => ({
  type: ActionType.UPDATE_USER_ERROR,
  payload: processErrorToString(err),
});

export const fetchUserPrivate = (): IAction<null> => ({
  type: ActionType.FETCH_USER_PRIVATE,
  payload: null,
});

export const userPrivateRequest = (uid: string): IAction<string> => ({
  type: ActionType.USER_PRIVATE_REQUEST,
  payload: uid,
});

export const userPrivateResponse = (
  user: TFirebaseUserPrivateProfile,
): IAction<TFirebaseUserPrivateProfile> => ({
  type: ActionType.USER_PRIVATE_RESPONSE,
  payload: user,
});

export const userPrivateError = (err: any): IAction<string> => ({
  type: ActionType.USER_PRIVATE_ERROR,
  payload: processErrorToString(err),
});

export const fetchUpdateUserPrivate = (
  userPrivate: Partial<TUserPrivateProfile>,
): IAction<Partial<TUserPrivateProfile>> => ({
  type: ActionType.FETCH_UPDATE_USER_PRIVATE,
  payload: userPrivate,
});

export const updateUserPrivateRequest = (
  userPrivate: Partial<TUserPrivateProfile>,
): IAction<Partial<TUserPrivateProfile>> => ({
  type: ActionType.UPDATE_USER_PRIVATE_REQUEST,
  payload: userPrivate,
});

export const updateUserPrivateResponse = (): IAction<null> => ({
  type: ActionType.UPDATE_USER_PRIVATE_RESPONSE,
  payload: null,
});

export const updateUserPrivateError = (err: any): IAction<string> => ({
  type: ActionType.UPDATE_USER_PRIVATE_ERROR,
  payload: processErrorToString(err),
});

export const fetchUserFollowing = (): IAction<null> => ({
  type: ActionType.FETCH_USER_FOLLOWING,
  payload: null,
});

export const userFollowingRequest = (uid: string): IAction<string> => ({
  type: ActionType.USER_FOLLOWING_REQUEST,
  payload: uid,
});

export const userFollowingResponse = (
  user: TFirebaseUserFollowList,
): IAction<TFirebaseUserFollowList> => ({
  type: ActionType.USER_FOLLOWING_RESPONSE,
  payload: user,
});

export const userFollowingError = (err: any): IAction<string> => ({
  type: ActionType.USER_FOLLOWING_ERROR,
  payload: processErrorToString(err),
});

export const userEntriesRequest = (uid: string): IAction<string> => ({
  type: ActionType.USER_ENTRIES_REQUEST,
  payload: uid,
});

export const userEntriesResponse = (
  user: TFirebaseCompEntry[],
): IAction<TFirebaseCompEntry[]> => ({
  type: ActionType.USER_ENTRIES_RESPONSE,
  payload: user,
});

export const userEntriesError = (err: any): IAction<string> => ({
  type: ActionType.USER_ENTRIES_ERROR,
  payload: processErrorToString(err),
});

export const userGroupsRequest = (uid: string): IAction<string> => ({
  type: ActionType.USER_GROUPS_REQUEST,
  payload: uid,
});

export const userGroupsResponse = (
  groups: TFirebaseGroup[],
): IAction<TFirebaseGroup[]> => ({
  type: ActionType.USER_GROUPS_RESPONSE,
  payload: groups,
});

export const userGroupsError = (err: any): IAction<string> => ({
  type: ActionType.USER_GROUPS_ERROR,
  payload: processErrorToString(err),
});

export const fetchInboxMessageOpen = (messageId: string): IAction<string> => ({
  type: ActionType.FETCH_INBOX_MESSAGE_OPEN,
  payload: messageId,
});

export const inboxMessagesRequest = (uid: string): IAction<string> => ({
  type: ActionType.INBOX_MESSAGES_REQUEST,
  payload: uid,
});

export const inboxMessagesResponse = (
  messages: TFirebaseInboxMessage[],
): IAction<TFirebaseInboxMessage[]> => ({
  type: ActionType.INBOX_MESSAGES_RESPONSE,
  payload: messages,
});

export const inboxMessagesError = (err: any): IAction<string> => ({
  type: ActionType.INBOX_MESSAGES_ERROR,
  payload: processErrorToString(err),
});

export const fetchFollowUser = (userId: string): IAction<string> => ({
  type: ActionType.FETCH_FOLLOW_USER,
  payload: userId,
});

export const fetchUnfollowUser = (userId: string): IAction<string> => ({
  type: ActionType.FETCH_UNFOLLOW_USER,
  payload: userId,
});

export const fetchEnterCompetition = (
  entry: IEnterCompPayload,
): IAction<IEnterCompPayload> => ({
  type: ActionType.FETCH_ENTER_COMPETITION,
  payload: entry,
});

export const enterCompetitionRequest = (compId: string): IAction<string> => ({
  type: ActionType.ENTER_COMPETITION_REQUEST,
  payload: compId,
});

export const enterCompetitionResponse = (compId: string): IAction<string> => ({
  type: ActionType.ENTER_COMPETITION_RESPONSE,
  payload: compId,
});

export const enterCompetitionError = (err: any): IAction<string> => ({
  type: ActionType.ENTER_COMPETITION_ERROR,
  payload: processErrorToString(err),
});

export const fetchLeaderboardConfig = (
  leaderboardId: string,
): IAction<string> => ({
  type: ActionType.FETCH_LEADERBOARD_CONFIG,
  payload: leaderboardId,
});

export const leaderboardConfigRequest = (
  leaderboardId: string,
): IAction<string> => ({
  type: ActionType.LEADERBOARD_CONFIG_REQUEST,
  payload: leaderboardId,
});

export const leaderboardConfigResponse = (
  leaderboardConfig: TFirebaseLeaderboard,
): IAction<TFirebaseLeaderboard> => ({
  type: ActionType.LEADERBOARD_CONFIG_RESPONSE,
  payload: leaderboardConfig,
});

export const leaderboardConfigError = (err: any): IAction<string> => ({
  type: ActionType.LEADERBOARD_CONFIG_ERROR,
  payload: processErrorToString(err),
});

export const fetchLeaderboard = (
  leaderboardId: string,
  isMultiGame: boolean = false,
): IAction<{leaderboardId: string; isMultiGame: boolean}> => ({
  type: ActionType.FETCH_LEADERBOARD,
  payload: {leaderboardId, isMultiGame},
});

export const leaderboardRequest = (path: string): IAction<string> => ({
  type: ActionType.LEADERBOARD_REQUEST,
  payload: path,
});

export const leaderboardResponse = (
  entrants: TFirebaseLeaders | TFirebaseMultiGameLeaders,
): IAction<TFirebaseLeaders | TFirebaseMultiGameLeaders> => ({
  type: ActionType.LEADERBOARD_RESPONSE,
  payload: entrants,
});

export const leaderboardError = (err: any): IAction<string> => ({
  type: ActionType.LEADERBOARD_ERROR,
  payload: processErrorToString(err),
});

export const competitionCountsRequest = (compId: string): IAction<string> => ({
  type: ActionType.COMPETITION_COUNTS_REQUEST,
  payload: compId,
});

export const competitionCountsResponse = (
  competitionCounts: TFirebaseCompCounts,
): IAction<TFirebaseCompCounts> => ({
  type: ActionType.COMPETITION_COUNTS_RESPONSE,
  payload: competitionCounts,
});

export const competitionCountsError = (err: any): IAction<string> => ({
  type: ActionType.COMPETITION_COUNTS_ERROR,
  payload: processErrorToString(err),
});

export const competitionVipsRequest = (vendorId: string): IAction<string> => ({
  type: ActionType.COMPETITION_VIPS_REQUEST,
  payload: vendorId,
});

export const competitionVipsResponse = (
  competitionVips: TFirebaseUser[],
): IAction<TFirebaseUser[]> => ({
  type: ActionType.COMPETITION_VIPS_RESPONSE,
  payload: competitionVips,
});

export const competitionVipsError = (err: any): IAction<string> => ({
  type: ActionType.COMPETITION_VIPS_ERROR,
  payload: processErrorToString(err),
});

export const competitionChatRequest = (compId: string): IAction<string> => ({
  type: ActionType.COMPETITION_CHAT_REQUEST,
  payload: compId,
});

export const competitionChatResponse = (
  chatMessages: TFirebaseChatMessage[],
): IAction<TFirebaseChatMessage[]> => ({
  type: ActionType.COMPETITION_CHAT_RESPONSE,
  payload: chatMessages,
});

export const competitionChatError = (err: any): IAction<string> => ({
  type: ActionType.COMPETITION_CHAT_ERROR,
  payload: processErrorToString(err),
});

export const fetchSendChat = (
  message: string,
  payload?: Partial<TChatMessagePayload>,
): IAction<{message: string; payload?: Partial<TChatMessagePayload>}> => ({
  type: ActionType.FETCH_SEND_CHAT,
  payload: {message, payload},
});

export const sendChatRequest = (
  message: TChatMessage,
): IAction<TChatMessage> => ({
  type: ActionType.SEND_CHAT_REQUEST,
  payload: message,
});

export const sendChatResponse = (): IAction<null> => ({
  type: ActionType.SEND_CHAT_RESPONSE,
  payload: null,
});

export const sendChatError = (err: any): IAction<string> => ({
  type: ActionType.SEND_CHAT_ERROR,
  payload: processErrorToString(err),
});

export const competitionStatusRequest = (compId: string): IAction<string> => ({
  type: ActionType.COMPETITION_STATUS_REQUEST,
  payload: compId,
});

export const competitionStatusResponse = (
  status: TFirebaseCompStatus,
): IAction<TFirebaseCompStatus> => ({
  type: ActionType.COMPETITION_STATUS_RESPONSE,
  payload: status,
});

export const competitionStatusError = (err: any): IAction<string> => ({
  type: ActionType.COMPETITION_STATUS_ERROR,
  payload: processErrorToString(err),
});

export const tempEntryRequest = (answers: TTempEntry): IAction<TTempEntry> => ({
  type: ActionType.TEMP_ENTRY_REQUEST,
  payload: answers,
});

export const tempEntryResponse = (
  compId: string,
  tempId: string,
): IAction<{competitionId: string; tempId: string}> => ({
  type: ActionType.TEMP_ENTRY_RESPONSE,
  payload: {competitionId: compId, tempId: tempId},
});

export const tempEntryError = (err: any): IAction<string> => ({
  type: ActionType.TEMP_ENTRY_ERROR,
  payload: processErrorToString(err),
});

export const fetchGetTempEntry = (id: string): IAction<string> => ({
  type: ActionType.FETCH_GET_TEMP_ENTRY,
  payload: id,
});

export const getTempEntryRequest = (id: string): IAction<string> => ({
  type: ActionType.GET_TEMP_ENTRY_REQUEST,
  payload: id,
});

export const getTempEntryResponse = (
  tempEntry: IFirebaseTempEntryWithComp,
): IAction<IFirebaseTempEntryWithComp> => ({
  type: ActionType.GET_TEMP_ENTRY_RESPONSE,
  payload: tempEntry,
});

export const getTempEntryError = (err: any): IAction<string> => ({
  type: ActionType.GET_TEMP_ENTRY_ERROR,
  payload: processErrorToString(err),
});

export const fetchAddGroup = (group: TGroup): IAction<TGroup> => ({
  type: ActionType.FETCH_ADD_GROUP,
  payload: group,
});

export const addGroupRequest = (group: TGroup): IAction<TGroup> => ({
  type: ActionType.ADD_GROUP_REQUEST,
  payload: group,
});

export const addGroupResponse = (
  group: TFirebaseGroup,
): IAction<TFirebaseGroup> => ({
  type: ActionType.ADD_GROUP_RESPONSE,
  payload: group,
});

export const addGroupError = (err: any): IAction<string> => ({
  type: ActionType.ADD_GROUP_ERROR,
  payload: processErrorToString(err),
});

export const addGroupReset = (): IAction<null> => ({
  type: ActionType.ADD_GROUP_RESET,
  payload: null,
});

export const fetchAddChallenge = (payload: {
  challengerId: string;
  compId: string;
}): IAction<{
  challengerId: string;
  compId: string;
}> => ({
  type: ActionType.FETCH_ADD_CHALLENGE,
  payload,
});

export const fetchEditGroup = (
  groupId: string,
  userId: string,
  group: Partial<TGroup>,
): IAction<{groupId: string; userId: string; group: Partial<TGroup>}> => ({
  type: ActionType.FETCH_EDIT_GROUP,
  payload: {groupId, userId, group},
});

export const editGroupRequest = (
  group: Partial<TGroup>,
): IAction<Partial<TGroup>> => ({
  type: ActionType.EDIT_GROUP_REQUEST,
  payload: group,
});

export const editGroupResponse = (groupId: string): IAction<string> => ({
  type: ActionType.EDIT_GROUP_RESPONSE,
  payload: groupId,
});

export const editGroupError = (err: any): IAction<string> => ({
  type: ActionType.EDIT_GROUP_ERROR,
  payload: processErrorToString(err),
});

export const fetchDeleteGroup = (groupId: string): IAction<string> => ({
  type: ActionType.FETCH_DELETE_GROUP,
  payload: groupId,
});

export const deleteGroupRequest = (groupId: string): IAction<string> => ({
  type: ActionType.DELETE_GROUP_REQUEST,
  payload: groupId,
});

export const deleteGroupResponse = (groupId: string): IAction<string> => ({
  type: ActionType.DELETE_GROUP_RESPONSE,
  payload: groupId,
});

export const deleteGroupError = (err: any): IAction<string> => ({
  type: ActionType.DELETE_GROUP_ERROR,
  payload: processErrorToString(err),
});

export const fetchGroup = (groupId: string): IAction<string> => ({
  type: ActionType.FETCH_GROUP,
  payload: groupId,
});

export const groupRequest = (groupId: string): IAction<string> => ({
  type: ActionType.GROUP_REQUEST,
  payload: groupId,
});

export const groupResponse = (
  group: TFirebaseGroup,
): IAction<TFirebaseGroup> => ({
  type: ActionType.GROUP_RESPONSE,
  payload: group,
});

export const groupError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_ERROR,
  payload: processErrorToString(err),
});

export const fetchSendGroupChat = (
  groupId: string,
  message: string,
  groupKey?: string,
  payload?: Partial<TChatMessagePayload>,
): IAction<{
  groupId: string;
  message: string;
  groupKey?: string;
  payload?: Partial<TChatMessagePayload>;
}> => ({
  type: ActionType.FETCH_SEND_GROUP_CHAT,
  payload: {groupId, message, groupKey, payload},
});

export const sendGroupChatRequest = (
  groupId: string,
  message: TChatMessage,
): IAction<{
  groupId: string;
  message: TChatMessage;
}> => ({
  type: ActionType.SEND_GROUP_CHAT_REQUEST,
  payload: {groupId, message},
});

export const sendGroupChatResponse = (): IAction<null> => ({
  type: ActionType.SEND_GROUP_CHAT_RESPONSE,
  payload: null,
});

export const sendGroupChatError = (err: any): IAction<string> => ({
  type: ActionType.SEND_GROUP_CHAT_ERROR,
  payload: processErrorToString(err),
});

export const fetchCloseGroup = (): IAction<null> => ({
  type: ActionType.FETCH_CLOSE_GROUP,
  payload: null,
});

export const fetchGroupDetails = (
  group: TFirebaseGroup,
): IAction<TFirebaseGroup> => ({
  type: ActionType.FETCH_GROUP_DETAILS,
  payload: group,
});

export const groupCountsRequest = (groupIds: string[]): IAction<string[]> => ({
  type: ActionType.GROUP_COUNTS_REQUEST,
  payload: groupIds,
});

export const groupCountsResponse = (groupCounts: {
  [groupId: string]: TFirebaseGroupCounts;
}): IAction<{[groupId: string]: TFirebaseGroupCounts}> => ({
  type: ActionType.GROUP_COUNTS_RESPONSE,
  payload: groupCounts,
});

export const groupCountsError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_COUNTS_ERROR,
  payload: processErrorToString(err),
});

export const groupPrivatesRequest = (
  groupIds: string[],
): IAction<string[]> => ({
  type: ActionType.GROUP_PRIVATES_REQUEST,
  payload: groupIds,
});

export const groupPrivatesResponse = (groupPrivates: {
  [groupId: string]: TFirebaseGroupPrivate;
}): IAction<{
  [groupId: string]: TFirebaseGroupPrivate;
}> => ({
  type: ActionType.GROUP_PRIVATES_RESPONSE,
  payload: groupPrivates,
});

export const groupPrivatesError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_PRIVATES_ERROR,
  payload: processErrorToString(err),
});

export const groupChatRequest = (groupId: string): IAction<string> => ({
  type: ActionType.GROUP_CHAT_REQUEST,
  payload: groupId,
});

export const groupChatResponse = (
  chatMessages: TFirebaseChatMessage[],
): IAction<TFirebaseChatMessage[]> => ({
  type: ActionType.GROUP_CHAT_RESPONSE,
  payload: chatMessages,
});

export const groupChatError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_CHAT_ERROR,
  payload: processErrorToString(err),
});

export const groupQuestionsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.GROUP_QUESTIONS_REQUEST,
  payload: compIds,
});

export const groupQuestionsResponse = (questions: {
  [compId: string]: TFirebaseQuestion[];
}): IAction<{[compId: string]: TFirebaseQuestion[]}> => ({
  type: ActionType.GROUP_QUESTIONS_RESPONSE,
  payload: questions,
});

export const groupQuestionsError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_QUESTIONS_ERROR,
  payload: processErrorToString(err),
});

export const groupCompCountsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.GROUP_COMPETITION_COUNTS_REQUEST,
  payload: compIds,
});

export const groupCompCountsResponse = (counts: {
  [compId: string]: TFirebaseCompCounts;
}): IAction<{
  [compId: string]: TFirebaseCompCounts;
}> => ({
  type: ActionType.GROUP_COMPETITION_COUNTS_RESPONSE,
  payload: counts,
});

export const groupCompCountsError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_COMPETITION_COUNTS_ERROR,
  payload: processErrorToString(err),
});

export const groupLiveLeaderboardsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.GROUP_LIVE_LEADERBOARDS_REQUEST,
  payload: compIds,
});

export const groupLiveLeaderboardsResponse = (counts: {
  [compId: string]: TFirebaseLeaders;
}): IAction<{
  [compId: string]: TFirebaseLeaders;
}> => ({
  type: ActionType.GROUP_LIVE_LEADERBOARDS_RESPONSE,
  payload: counts,
});

export const groupLiveLeaderboardsError = (err: any): IAction<string> => ({
  type: ActionType.GROUP_LIVE_LEADERBOARDS_ERROR,
  payload: processErrorToString(err),
});

export const fetchVendorCompDetails = (vendor: string): IAction<string> => ({
  type: ActionType.FETCH_VENDOR_COMP_DETAILS,
  payload: vendor,
});

export const fetchVendorMiniLeagueDetails = (
  vendor: string,
): IAction<string> => ({
  type: ActionType.FETCH_VENDOR_MINI_LEAGUE_DETAILS,
  payload: vendor,
});

export const fetchCloseVendor = (): IAction<null> => ({
  type: ActionType.FETCH_CLOSE_VENDOR,
  payload: null,
});

export const vendorQuestionsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.VENDOR_QUESTIONS_REQUEST,
  payload: compIds,
});

export const vendorQuestionsResponse = (questions: {
  [compId: string]: TFirebaseQuestion[];
}): IAction<{[compId: string]: TFirebaseQuestion[]}> => ({
  type: ActionType.VENDOR_QUESTIONS_RESPONSE,
  payload: questions,
});

export const vendorQuestionsError = (err: any): IAction<string> => ({
  type: ActionType.VENDOR_QUESTIONS_ERROR,
  payload: processErrorToString(err),
});

export const vendorNewsRequest = (tags: string[]): IAction<string[]> => ({
  type: ActionType.VENDOR_NEWS_REQUEST,
  payload: tags,
});

export const vendorNewsResponse = (
  news: TFirebaseNewsItem[],
): IAction<TFirebaseNewsItem[]> => ({
  type: ActionType.VENDOR_NEWS_RESPONSE,
  payload: news,
});

export const vendorNewsError = (err: any): IAction<string> => ({
  type: ActionType.VENDOR_NEWS_ERROR,
  payload: processErrorToString(err),
});

export const vendorCompCountsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.VENDOR_COMPETITION_COUNTS_REQUEST,
  payload: compIds,
});

export const vendorCompCountsResponse = (counts: {
  [compId: string]: TFirebaseCompCounts;
}): IAction<{
  [compId: string]: TFirebaseCompCounts;
}> => ({
  type: ActionType.VENDOR_COMPETITION_COUNTS_RESPONSE,
  payload: counts,
});

export const vendorCompCountsError = (err: any): IAction<string> => ({
  type: ActionType.VENDOR_COMPETITION_COUNTS_ERROR,
  payload: processErrorToString(err),
});

export const vendorLiveLeaderboardsRequest = (
  compIds: string[],
): IAction<string[]> => ({
  type: ActionType.VENDOR_LIVE_LEADERBOARDS_REQUEST,
  payload: compIds,
});

export const vendorLiveLeaderboardsResponse = (counts: {
  [compId: string]: TFirebaseLeaders;
}): IAction<{
  [compId: string]: TFirebaseLeaders;
}> => ({
  type: ActionType.VENDOR_LIVE_LEADERBOARDS_RESPONSE,
  payload: counts,
});

export const vendorLiveLeaderboardsError = (err: any): IAction<string> => ({
  type: ActionType.VENDOR_LIVE_LEADERBOARDS_ERROR,
  payload: processErrorToString(err),
});

export const vendorMiniLeagueLeaderboardsRequest = (
  leaderboardId: string,
): IAction<string> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_REQUEST,
  payload: leaderboardId,
});

export const vendorMiniLeagueLeaderboardsResponse = (
  leaders: TFirebaseMultiGameLeaders,
): IAction<TFirebaseMultiGameLeaders> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_RESPONSE,
  payload: leaders,
});

export const vendorMiniLeagueLeaderboardsError = (
  err: any,
): IAction<string> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_ERROR,
  payload: processErrorToString(err),
});

export const vendorMiniLeagueLeaderboardConfigRequest = (
  leaderboardId: string,
): IAction<string> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_CONFIG_REQUEST,
  payload: leaderboardId,
});

export const vendorMiniLeagueLeaderboardConfigResponse = (
  leaders: TFirebaseLeaderboard,
): IAction<TFirebaseLeaderboard> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_CONFIG_RESPONSE,
  payload: leaders,
});

export const vendorMiniLeagueLeaderboardConfigError = (
  err: any,
): IAction<string> => ({
  type: ActionType.VENDOR_MINI_LEAGUE_LEADERBOARD_CONFIG_ERROR,
  payload: processErrorToString(err),
});

export const announcementsRequest = (): IAction<null> => ({
  type: ActionType.ANNOUNCEMENTS_REQUEST,
  payload: null,
});

export const announcementsResponse = (
  announcements: TFirebaseAnnouncement[],
): IAction<TFirebaseAnnouncement[]> => ({
  type: ActionType.ANNOUNCEMENTS_RESPONSE,
  payload: announcements,
});

export const announcementsError = (err: any): IAction<string> => ({
  type: ActionType.ANNOUNCEMENTS_ERROR,
  payload: processErrorToString(err),
});

export const newsRequest = (): IAction<null> => ({
  type: ActionType.NEWS_REQUEST,
  payload: null,
});

export const newsResponse = (
  news: TFirebaseNewsItem[],
): IAction<TFirebaseNewsItem[]> => ({
  type: ActionType.NEWS_RESPONSE,
  payload: news,
});

export const newsError = (err: any): IAction<string> => ({
  type: ActionType.NEWS_ERROR,
  payload: processErrorToString(err),
});
