import {createSelector} from 'reselect';
import {TFirebaseCompSummary} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {EXCLUDED_GROUPS_VENDORS} from '../../../interfaces/firestore/FirestoreEnums';
import {TCompSummary} from '../../../interfaces/firestore/FirestoreInterfaces';
import {createCompetitionVendor} from '../../../utils/CompetitionUtils';
import {IAppState} from '../../state/AppState';
import {
  ICompSummaryAndEntry,
  IVendorDetails,
} from '../../state/model/CompetitionModel';
import {getFilteredCompSummaries} from '../competitions/BaseCompSummarySelectors';

export const getVendors = createSelector(
  getFilteredCompSummaries,
  (competitions) => {
    return competitions.reduce(
      (p: IVendorDetails[], v: TCompSummary, index: number) => {
        if (p.find((s) => s.vendor === v.vendor) === undefined) {
          p.push(createCompetitionVendor(v));
        }
        return p;
      },
      [],
    );
  },
);

export const getVendorsMap = createSelector(getVendors, (vendors) => {
  return vendors.reduce((p, v) => {
    p.set(v.vendor, v);
    return p;
  }, new Map<string, IVendorDetails>());
});

export const getVendorsForGroups = createSelector(getVendors, (vendors) =>
  vendors.filter((v) => !EXCLUDED_GROUPS_VENDORS.includes(v.vendor)),
);

export const getVendorsForGroupsByIdMap = createSelector(
  getVendorsForGroups,
  (vendors) => {
    return vendors.reduce((p, v) => {
      p.set(v.vendor, v);
      return p;
    }, new Map<string, IVendorDetails>());
  },
);

export const getCompsForVendor = (
  comps: ICompSummaryAndEntry[],
  vendor: string | undefined,
): ICompSummaryAndEntry[] => {
  if (vendor === undefined) {
    return comps;
  }
  return comps.filter((comp) => comp.summary.vendor === vendor);
};

export const getMiniLeagueLeaderboard = (comp: TFirebaseCompSummary) => {
  const leaderboard = comp.leaderboards.find(
    (l) => l.isMiniLeague && l.published,
  );
  if (leaderboard === undefined) {
    throw new Error('No mini game leaderboard found for comp');
  }
  return leaderboard;
};

export const getVendorCompCounts = (state: IAppState) =>
  state.serverApis.firestore.vendorCompCounts.response;

export const getVendorQuestions = (state: IAppState) =>
  state.serverApis.firestore.vendorQuestions.response;

export const getVendorLiveLeaderboards = (state: IAppState) =>
  state.serverApis.firestore.vendorLiveLeaderboards.response;

export const getVendorMiniLeagueLeaderboard = (state: IAppState) =>
  state.serverApis.firestore.vendorMiniLeagueLeaderboard.response;

export const getVendorMiniLeagueLeaderboardConfig = (state: IAppState) =>
  state.serverApis.firestore.vendorMiniLeagueLeaderboardConfig.response;

export const getVendorNews = (state: IAppState) =>
  state.serverApis.firestore.vendorNews.response;
