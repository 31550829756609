import React, {useCallback, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {
  ClientStatus,
  ICompSummaryAndEntry,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {CompStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {IColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {getCompetitionClientStatus} from '@chancer/common/lib/utils/CompetitionUtils';
import {showLiveScore} from '@chancer/common/lib/utils/LiveScoreUtils';
import {Avatar} from '../Avatar/Avatar';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_GREY_6,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

export interface ICompDetailsListItemProps {
  containerStyle?: CompositedViewStyle;
  comp: ICompSummaryAndEntry;
  colorConfig: IColorConfiguration;
  isMini?: boolean;
  noColorBackground?: boolean;
  promoMode?: boolean;
  children?: React.ReactNode;
  onPress?: (details: ICompSummaryAndEntry) => void;
}

export const CompDetailsListItem: React.FC<ICompDetailsListItemProps> = (
  props,
) => {
  const {comp, colorConfig, onPress, isMini} = props;
  const isUpcoming = comp.summary.status === CompStatus.DRAFT;
  const clientStatus = getCompetitionClientStatus(
    comp.summary.starts,
    comp.summary.status,
  );

  const contextualContainer: CompositedViewStyle = useMemo(
    () => [
      styles.container,
      props.containerStyle,
      props.noColorBackground !== true && {
        backgroundColor: colorConfig.leaderboardItemsBackground,
      },
      isUpcoming && {opacity: 0.5},
    ],
    [
      props.containerStyle,
      props.noColorBackground,
      colorConfig.leaderboardItemsBackground,
      isUpcoming,
    ],
  );

  const _onPress = useCallback(() => {
    if (onPress) {
      onPress(comp);
    }
  }, [comp, onPress]);

  return (
    <Pressable
      style={contextualContainer}
      disabled={isUpcoming || !onPress}
      onPress={_onPress}>
      <>
        <Avatar
          imageUrl={comp.summary.promo.image?.url}
          highlightWidth={0}
          highlightGap={0}
          size={isMini !== true ? 48 : 32}
          radius={8}
        />
        <View style={styles.copyContainer}>
          {props.promoMode ? (
            <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
              {comp.summary.shortName}
            </Text>
          ) : showLiveScore(props.comp.summary.liveScore) ? (
            <View>
              <LiveScore
                key="home"
                name={comp.summary.liveScore?.homeName ?? ''}
                score={comp.summary.liveScore?.homeScore ?? 0}
              />
              <LiveScore
                key="away"
                name={comp.summary.liveScore?.awayName ?? ''}
                score={comp.summary.liveScore?.awayScore ?? 0}
              />
            </View>
          ) : (
            <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
              {comp.summary.shortName}
            </Text>
          )}
          <View style={styles.detailsContainer}>
            {((props.comp.entry === null &&
              props.comp.summary.status === CompStatus.OPEN) ||
              props.comp.outstandingQuestions > 0) && (
              <LabelBadge
                label={`${
                  props.comp.entry !== null ||
                  props.comp.summary.status !== CompStatus.OPEN
                    ? `${props.comp.outstandingQuestions} `
                    : ''
                }NEW`}
                labelColor={COLOR_DARK}
                backgroundColor={COLOR_YELLOW}
              />
            )}
            {(props.comp.summary.liveScore &&
              props.comp.summary.liveScore.isLive === true) ||
              ((clientStatus === ClientStatus.IN_PLAY ||
                props.comp.summary.inPlayQuestions.length > 0) && (
                <LabelBadge label="LIVE" />
              ))}
            <Text style={styles.details} numberOfLines={1} ellipsizeMode="tail">
              {!props.promoMode
                ? `${comp.summary.roundName}, ${formatDate(comp.summary.starts.toDate())} ∙ ${
                    comp.summary.entriesCount
                  } fans`
                : `${comp.summary.name}`}
            </Text>
          </View>
        </View>
        {props.children}
      </>
    </Pressable>
  );
};

// Today, Yesterday or Apr 1
const formatDate = (date: Date) => {
  const now = new Date();
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Today';
  }

  now.setDate(now.getDate() - 1);
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Yesterday';
  }

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    position: 'relative',
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 2,
    overflow: 'hidden',
    width: '100%',
  },
  copyContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    marginLeft: 12,
    overflow: 'hidden',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 14,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    gap: 4,
  },
  details: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY,
    fontSize: 12,
  },
  liveScoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const LiveScore: React.FC<{name: string; score: number}> = (props) => {
  return (
    <View style={styles.liveScoreContainer}>
      <Text style={styles.name}>{props.name}</Text>
      <Text style={styles.name}>{props.score}</Text>
    </View>
  );
};
