import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import React, {useMemo} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {CompositedViewStyle} from '../../Styles/StyleTypes';

interface IProps {
  containerStyle?: CompositedViewStyle;
  children: React.ReactNode;
  color: string;
  onClose: () => void;
}

export const StartupOverlayScreenContainer: React.FC<IProps> = (props) => {
  const {color} = props;

  const backgroundColors = useMemo(() => {
    const colorConfig = getColorConfiguration(color);
    return [colorConfig.overlayTop, colorConfig.overlayBottom];
  }, [color]);
  return (
    <Pressable style={styles.pressableContainer} onPress={props.onClose}>
      <LinearGradient
        colors={backgroundColors}
        style={[styles.container, props.containerStyle]}>
        {props.children}
      </LinearGradient>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  pressableContainer: {
    flex: 1,
  },
  container: {},
});
