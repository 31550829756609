import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Score} from '../Leaderboard/Score';
import {CompStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {showLiveScore} from '@chancer/common/lib/utils/LiveScoreUtils';

interface IProps {
  comp: ICompSummaryAndEntry;
}

export const MiniLeagueSummaryScore: React.FC<IProps> = (props) => {
  const {comp} = props;
  return comp.entry && comp.entry.verifiedScore !== undefined ? (
    <View style={styles.pointsContainer}>
      <Score score={comp.entry.verifiedScore} size="medium" />
    </View>
  ) : comp.summary.status === CompStatus.RESULTED ||
    comp.summary.status === CompStatus.VERIFIED ? (
    <View style={styles.pointsContainer}>
      <Score score={'-'} size="medium" />
    </View>
  ) : showLiveScore(comp.summary.liveScore) ? (
    <View style={styles.pointsContainer} />
  ) : null;
};

const styles = StyleSheet.create({
  pointsContainer: {
    width: 64,
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    paddingTop: 8,
  },
});
