import React, {ReactElement, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {getAchievementTitle} from '@chancer/common/lib/interfaces/achievements/AchievementSpec';
import {AchievementIcon} from '../../Achievements/AchievementIcon';
import {
  COLOR_GREY_4,
  COLOR_LIGHT,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {CompSummaryDetail} from '../Startup/SummaryDetail';
import {StartupOverlayScreenContainer} from './StartupOverlayScreenContainer';

interface IProps {
  safeAreaTopPadding?: number;
  color: string;
  compName: string;
  vendor: string;
  score: number;
  customIcon?: ReactElement;
  achievement: AchievementType;
  onClose: () => void;
}

export const AchievementContent: React.FC<IProps> = (props) => {
  const {color, safeAreaTopPadding = 0} = props;
  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );

  return (
    <StartupOverlayScreenContainer
      containerStyle={styles.container}
      color={color}
      onClose={props.onClose}>
      <View style={styles.iconContainer}>
        {props.customIcon ? (
          props.customIcon
        ) : (
          <AchievementIcon achievement={props.achievement} size={180} />
        )}
      </View>
      <Text style={styles.title}>
        {getAchievementTitle(
          props.achievement,
          props.achievement === AchievementType.GAME_WINNER
            ? props.compName
            : undefined,
        )}
      </Text>
      <View style={styles.divider} />
      <View style={styles.detailsContainer}>
        {props.achievement === AchievementType.GAME_WINNER ? (
          <>
            <CompSummaryDetail
              value={props.score}
              title="You scored the most points"
              description={
                'The Mixnpik bot has picked you to get the prize. Congratulations'
              }
            />
            {/* <PrimaryButton style={[styles.button]} label="Share your win" /> */}
          </>
        ) : (
          <CompSummaryDetail
            value={props.score}
            title="You scored the most points"
            description={`You are one of the highest scorers in the ${props.compName} game`}
          />
        )}
      </View>
    </StartupOverlayScreenContainer>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    container: {
      flex: 1,
      height: '100%',
      color: COLOR_GREY_4,
      paddingTop: 120 + safeAreaTopPadding,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    iconContainer: {
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontFamily: FONT_TITLE,
      textAlign: 'center',
      fontSize: 24,
      color: COLOR_LIGHT,
      marginVertical: 16,
    },
    detailsContainer: {
      flex: 1,
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: 24,
    },
    divider: {width: '100%', backgroundColor: COLOR_GREY_4, height: 1},
  });
