import React, {useMemo} from 'react';
import {Image, View, ImageStyle, ViewStyle, StyleProp} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {COLOR_GREY_3} from '../Styles/DesignSystem-chancer';

interface IProps {
  style?: StyleProp<ViewStyle>;
  size: number;
  imageUrl: string | null | undefined;
  opacity?: number;
  defaultIconColor?: string;
  highlightColor?: string;
  highlightWidth?: number;
  highlightGap?: number;
  radius?: number;
}

export const Avatar: React.FC<IProps> = (props) => {
  const {size, radius, highlightColor, opacity, defaultIconColor, imageUrl} =
    props;
  const highlightWidth = props.highlightWidth ?? 2;
  const highlightGap = props.highlightGap ?? 0;
  const totalSize = size + (highlightWidth + highlightGap) * 2;

  const style: ViewStyle = useMemo(
    () => ({
      width: totalSize,
      height: totalSize,
      borderRadius:
        radius !== undefined
          ? radius + highlightWidth + highlightGap
          : totalSize / 2,
      overflow: 'hidden',
      borderWidth: highlightWidth,
      borderColor: highlightColor,
      opacity: opacity ?? 1,
    }),
    [totalSize, highlightWidth, highlightGap, highlightColor, opacity, radius],
  );
  const imageStyle: ImageStyle = useMemo(
    () => ({
      width: size,
      height: size,
      borderRadius: radius ?? size / 2,
      overflow: 'hidden',
      top: highlightGap,
      left: highlightGap,
    }),
    [size, radius, highlightGap],
  );

  const image = useMemo(() => {
    if (imageUrl) {
      return (
        <Image
          style={imageStyle}
          source={{
            uri: imageUrl,
          }}
        />
      );
    } else {
      return (
        <View style={imageStyle}>
          <Svg width={size} height={size} viewBox="0 0 48 48">
            <Path
              d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0zm-4.556 25H18.4a8.402 8.402 0 0 0-8.4 8.4V36a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-2.6c0-4.637-3.763-8.4-8.4-8.4h-1.044c-1.387.638-2.931 1-4.556 1s-3.163-.362-4.556-1zM24 7a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8z"
              fill={defaultIconColor ?? COLOR_GREY_3}
              fillRule="evenodd"
            />
          </Svg>
        </View>
      );
    }
  }, [size, imageUrl, defaultIconColor, imageStyle]);

  return <View style={[style, props.style]}>{image}</View>;
};
