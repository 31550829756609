import {
  IEnterCompOverlayProps,
  IOverlayViewProps,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {fetchEnterCompetition} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {IRemoteEnterCompOverlayConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {
  getCompetition,
  getQuestions,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {
  getCurrentCompetitionSummaryAndEntry,
  getUpcomingCompetitionsExcludingEntered,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {getVendorsMiniLeagueGamesMap} from '@chancer/common/lib/core/selectors/competitions/MiniLeagueCompetitonSummarySelectors';
import {getRemoteEnterCompOverlayConfig} from '@chancer/common/lib/core/selectors/remoteConfig/RemoteConfigSelectors';
import {
  getUser,
  getUsersCurrentCompEntryStatus,
} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {
  ICompSummaryAndEntry,
  IVendorDetails,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {
  AppPlatform,
  EnterCompContentType,
  EnterCompStatus,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {EnterCompContent} from '@chancer/components/lib/EnterCompetition/EnterCompContent';
import {EnterMiniLeagueCompContent} from '@chancer/components/lib/EnterCompetition/EnterMiniLeagueCompContent';
import {useSetOnce} from '@chancer/components/lib/Hooks/CommonHooks';
import {Lockable} from '@chancer/components/lib/Utils/Lockable';
import {
  trackEnterCompOverlay,
  trackScreenView,
  trackSelectContent,
} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig, getConfig} from '../../../config/WebConfig';
import {MediaView} from '../../Core/Media/MediaView';
import {BottomSheet} from '../Containers/BottomSheet';
import './EnterCompOverlay.scss';

interface IProps extends IEnterCompOverlayProps, IOverlayViewProps {}

interface IStateProps {
  vendor: IVendorDetails | null;
  user: TFirebaseUser | null;
  comp: TFirebaseComp | null;
  remoteEnterCompOverlayConfig: IRemoteEnterCompOverlayConfig;
  questions: TFirebaseQuestion[] | null;
  compEntryStatus: EnterCompStatus;
  leaderboardComps: ICompSummaryAndEntry[];
  upcomingComps: ICompSummaryAndEntry[];
  config: IWebConfig;
}

interface IDispatchProps {
  onEnterCompetition: () => void;
}

export const EnterCompOverlay: React.FC<IProps> = (props) => {
  const {entry} = props;
  const dispatch = useDispatch();
  const fetchEnterCompetitionCallback = useCallback(
    () => dispatch(fetchEnterCompetition(entry)),
    [dispatch, entry],
  );

  const comp = useSelector(getCurrentCompetitionSummaryAndEntry);
  const miniLeagueCompsMap = useSelector(getVendorsMiniLeagueGamesMap);
  const leaderboardComps = useMemo(
    () =>
      comp && comp.vendor.isMiniLeague
        ? miniLeagueCompsMap.get(comp.vendor.vendor) ?? []
        : [],
    [comp, miniLeagueCompsMap],
  );

  return (
    <EnterCompOverlayView
      {...props}
      vendor={comp?.vendor ?? null}
      user={useSelector(getUser)}
      comp={useSelector(getCompetition)}
      remoteEnterCompOverlayConfig={useSelector(
        getRemoteEnterCompOverlayConfig,
      )}
      questions={useSelector(getQuestions)}
      compEntryStatus={useSelector(getUsersCurrentCompEntryStatus)}
      upcomingComps={useSelector(getUpcomingCompetitionsExcludingEntered)}
      leaderboardComps={leaderboardComps}
      config={useSelector(getConfig)}
      onEnterCompetition={fetchEnterCompetitionCallback}
    />
  );
};

export const EnterCompOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const {entry, user, comp, onClose} = props;
  const navigate = useNavigate();
  const isMiniLeague = props.vendor?.isMiniLeague ?? false;
  const [locked, setLocked] = useState(false);

  const _onClose = useCallback(() => {
    setLocked(true);
    onClose();
  }, [onClose]);

  const _onHomeCallback = useCallback(() => {
    navigate(
      {
        pathname: '/',
        search: window.location.search,
      },
      {
        state: {
          canGoBack: true,
        },
      },
    );
    _onClose();
  }, [navigate, _onClose]);

  const _onCompetitionSelected = useCallback(
    (comp: ICompSummaryAndEntry) => {
      trackSelectContent('enter_comp_overlay__competition_selected');
      navigate(
        {
          pathname: `/${comp.summary.vendor}/${comp.summary.key}`,
          search: window.location.search,
        },
        {
          state: {
            canGoBack: true,
          },
        },
      );
      _onClose();
    },
    [navigate, _onClose],
  );

  const _getMediaComponent = () =>
    comp?.signUpCompleteView?.media ? (
      <MediaView
        className="enter-game__media-container"
        media={comp.signUpCompleteView?.media}
      />
    ) : undefined;

  let description = comp?.signUpCompleteView?.webDescription;
  if (description === undefined || description === '') {
    description = comp?.signUpCompleteView?.description;
  }
  const compsPlayed = user?.achievements
    ? user.achievements[AchievementType.PARTICIPANT] ?? 0
    : 0;
  const vendorCompsPlayed =
    comp?.vendor &&
    user?.vendorAchievements &&
    user.vendorAchievements[comp.vendor]
      ? user.vendorAchievements[comp.vendor][AchievementType.PARTICIPANT] ?? 0
      : 0;
  const isEntryWithMissedQuestions =
    entry.isEntry &&
    props.questions !== null &&
    props.questions.length !== Object.keys(entry.answers.answers).length;

  const enterCompContentType = useSetOnce(
    useMemo(() => {
      if (isMiniLeague) {
        return EnterCompContentType.MINI_LEAGUE;
      }
      if (!entry.isEntry) {
        return EnterCompContentType.ANSWERED_OUTSTANDING_QUESTIONS;
      }
      // if (compsPlayed < 4) {
      //   return EnterCompContentType.FIRST_N_GAMES;
      // }
      if (compsPlayed < 1 || vendorCompsPlayed < 1) {
        return EnterCompContentType.FIRST_N_VENDOR_GAMES;
      }
      return EnterCompContentType.DEFAULT;
    }, [isMiniLeague, compsPlayed, entry.isEntry, vendorCompsPlayed]),
  );

  const backgroundColors = useMemo(() => {
    const colorConfig = getColorConfiguration(comp?.primaryColor);
    return [colorConfig.overlayTop, colorConfig.overlayBottom];
  }, [comp?.primaryColor]);

  useEffect(() => {
    trackScreenView(`EnterCompOverlay_${enterCompContentType}`);
  }, [enterCompContentType]);

  return (
    <Lockable isLocked={locked}>
      <BottomSheet
        displayState={props.displayState}
        backgroundColor={backgroundColors}
        transitionDuration={props.transitionDuration}
        onClose={_onClose}
        onTransitionOutComplete={props.onTransitionOutComplete}>
        {isMiniLeague ? (
          <EnterMiniLeagueCompContent
            type={enterCompContentType}
            appPlatform={AppPlatform.WEB}
            primaryColor={comp?.primaryColor}
            questions={props.questions}
            compEntryStatus={props.compEntryStatus}
            mediaComponent={_getMediaComponent()}
            currentUser={user}
            gameUrl={props.config.gameUrl}
            cdnUrl={props.config.cdnUrl}
            vendorImageUrl={comp?.promo.image?.url}
            leaderboardComps={props.leaderboardComps}
            isEntryWithMissedQuestions={isEntryWithMissedQuestions}
            vendorName={comp?.promo.caption}
            onScreenAnalytics={trackEnterCompOverlay}
            onRetryEntry={props.onEnterCompetition}
            onCompetitionSelected={_onCompetitionSelected}
            onHome={_onHomeCallback}
          />
        ) : (
          <EnterCompContent
            type={enterCompContentType}
            remoteConfig={props.remoteEnterCompOverlayConfig}
            appPlatform={AppPlatform.WEB}
            title={comp?.signUpCompleteView?.media?.caption}
            primaryColor={comp?.primaryColor}
            description={description}
            questions={props.questions}
            compEntryStatus={props.compEntryStatus}
            upcomingComps={props.upcomingComps}
            challenge={null}
            mediaComponent={_getMediaComponent()}
            currentUser={user}
            gameUrl={props.config.gameUrl}
            cdnUrl={props.config.cdnUrl}
            vendorImageUrl={comp?.promo.image?.url}
            compsPlayed={compsPlayed}
            isEntryWithMissedQuestions={isEntryWithMissedQuestions}
            vendorName={comp?.promo.caption}
            onScreenAnalytics={trackEnterCompOverlay}
            onRetryEntry={props.onEnterCompetition}
            onCompetitionSelected={_onCompetitionSelected}
            onChallengeSelected={null}
            onNewChallengeSelected={null}
          />
        )}
      </BottomSheet>
    </Lockable>
  );
};
