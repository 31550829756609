import React, {useEffect, useMemo, useState} from 'react';
import {View, StyleSheet, Animated, Easing} from 'react-native';
import {COLOR_GREY_2, COLOR_GREY_4} from '../../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../../Styles/StyleTypes';
import {platformValue} from '../../PlatformUtils';

interface IProps {
  style?: CompositedViewStyle;
  currentColor?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  screenIndex: number;
  totalScreens: number;
  screenDurationMs?: number;
  onNextScreen?: () => void;
}

export const StoryProgressBar: React.FC<IProps> = (props) => {
  const {
    screenIndex,
    totalScreens,
    screenDurationMs = 10 * 1000,
    backgroundColor = COLOR_GREY_2,
    foregroundColor = COLOR_GREY_4,
    currentColor = foregroundColor,
    onNextScreen,
  } = props;
  const styles = useMemo(
    () => getStyles(backgroundColor, foregroundColor),
    [backgroundColor, foregroundColor],
  );
  const [moveAnim] = useState(new Animated.Value(0));

  // Start animation when screen index changes
  useEffect(() => {
    moveAnim.setValue(0);
    Animated.timing(moveAnim, {
      toValue: 1,
      duration: screenDurationMs,
      useNativeDriver: platformValue(true, {web: false}),
      easing: Easing.linear,
    }).start((result) => {
      result.finished && onNextScreen && onNextScreen();
    });
  }, [moveAnim, screenIndex, screenDurationMs, totalScreens, onNextScreen]);

  return (
    <View style={[styles.container, props.style]}>
      {Array.from({length: totalScreens}).map((_, i) => (
        <View key={i} style={styles.screenContainer}>
          <View style={styles.screenBackground} />
          <Animated.View
            style={[
              styles.progress,
              {
                ...(screenIndex === i
                  ? {
                      transform: [{scaleX: moveAnim}],
                      backgroundColor: currentColor,
                    }
                  : screenIndex < i
                    ? {display: 'none'}
                    : {}),
              },
            ]}
          />
        </View>
      ))}
    </View>
  );
};

const getStyles = (backgroundColor: string, foregroundColor: string) =>
  StyleSheet.create({
    container: {
      position: 'relative',
      width: '100%',
      height: 4,
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
    },
    screenContainer: {
      height: 4,
      flex: 1,
    },
    screenBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: 4,
      borderRadius: 2,
      width: '100%',
      backgroundColor,
    },
    progress: {
      top: 0,
      left: 0,
      height: 4,
      borderRadius: 2,
      transformOrigin: 'left',
      backgroundColor: foregroundColor,
    },
  });
