import React, {ReactElement, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {
  COLOR_GREY_4,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY_MEDIUM,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {StartupOverlayScreenContainer} from './StartupOverlayScreenContainer';

interface IProps {
  safeAreaTopPadding?: number;
  color: string;
  title: string;
  description: string;
  media: ReactElement;
  onClose: () => void;
}

export const AnnouncementContent: React.FC<IProps> = (props) => {
  const {color, safeAreaTopPadding = 0} = props;
  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );

  return (
    <StartupOverlayScreenContainer
      containerStyle={styles.container}
      color={color}
      onClose={props.onClose}>
      <View style={styles.mediaContainer}>{props.media}</View>
      <Text style={styles.title}>{props.title}</Text>
      <Text style={styles.description}>{props.description}</Text>
    </StartupOverlayScreenContainer>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    container: {
      flex: 1,
      height: '100%',
      color: COLOR_GREY_4,
      paddingTop: 90 + safeAreaTopPadding,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    mediaContainer: {
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '100%',
      aspectRatio: 1,
      overflow: 'hidden',
    },
    title: {
      width: '100%',
      fontFamily: FONT_TITLE,
      fontSize: 21,
      color: COLOR_LIGHT,
      marginTop: 32,
    },
    description: {
      width: '100%',
      marginTop: 16,
      fontSize: 16,
      fontFamily: FONT_COPY_MEDIUM,
      color: COLOR_GREY_8,
    },
  });
