import {combineEpics} from 'redux-observable';

import {processNewAchievementsEpic} from '@chancer/common/lib/core/epics/achievements/AchievementEpics';
import {
  cleanLocalAnnouncementViewCountEpic,
  setLocalAnnouncementViewCountEpic,
} from '@chancer/common/lib/core/epics/announcements/AnnouncementsEpics';
import {writeInitialDisabledContentBundleEpic} from '@chancer/common/lib/core/epics/bundles/BundleEpics';
import {setLocalChatReadDateEpic} from '@chancer/common/lib/core/epics/chat/ChatEpics';
import {uploadProfileImageEpic} from '@chancer/common/lib/core/epics/mediaUploads/MediaUploadEpics';
import {
  readLocalAnswersEpic,
  writeLocalAnswersEpic,
} from './answers/AnswersEpics';
import {
  doAnonymousLoginEpic,
  logOutEpic,
  startupLoginEpic,
} from './auth/AuthEpics';
import {
  enterCompetitionEpic,
  fetchCompetitionDetailsEpic,
  fetchCompetitionEpic,
  fetchCompetitionsEpic,
  fetchDeleteUserEpic,
  fetchFollowUserEpic,
  fetchGetTempEntryEpic,
  fetchGroupEpic,
  fetchInboxMessageOpenEpic,
  fetchLeaderboardConfigEpic,
  fetchLeaderboardEpic,
  fetchUnfollowUserEpic,
  fetchUpdateUserEpic,
  fetchUpdateUserPrivateEpic,
  fetchUserEpic,
  fetchUserFollowingEpic,
  fetchVendorCompDetailsEpic,
  fetchVendorMiniLeagueDetailsEpic,
  saveEntryDetailsToTempDoc,
} from './firestore/WebFirestoreEpics';
import {
  initLocalStorageEpic,
  writeLocalStorageEpic,
} from './localStorage/LocalStorageEpics';
import {uploadImageEpic} from './mediaUploads/MediaUploadEpics';
import {loadRemoteConfigEpic} from './remoteConfig/RemoteConfigEpics';
import {
  preloadLazyViewsEpic,
  trackOpenDetailsEpic,
} from './startup/StartupEpics';

export const rootEpic = combineEpics(
  fetchCompetitionsEpic,
  fetchCompetitionEpic,
  fetchCompetitionDetailsEpic,
  fetchUserEpic,
  fetchUpdateUserEpic,
  fetchUpdateUserPrivateEpic,
  fetchDeleteUserEpic,
  enterCompetitionEpic,
  saveEntryDetailsToTempDoc,
  fetchGetTempEntryEpic,
  fetchUserFollowingEpic,
  fetchFollowUserEpic,
  fetchUnfollowUserEpic,
  fetchLeaderboardEpic,
  fetchLeaderboardConfigEpic,
  fetchInboxMessageOpenEpic,
  logOutEpic,
  startupLoginEpic,
  doAnonymousLoginEpic,
  loadRemoteConfigEpic,
  readLocalAnswersEpic,
  writeLocalAnswersEpic,
  preloadLazyViewsEpic,
  uploadProfileImageEpic,
  uploadImageEpic,
  initLocalStorageEpic,
  writeLocalStorageEpic,
  processNewAchievementsEpic,
  trackOpenDetailsEpic,
  writeInitialDisabledContentBundleEpic,
  cleanLocalAnnouncementViewCountEpic,
  setLocalAnnouncementViewCountEpic,
  setLocalChatReadDateEpic,
  fetchGroupEpic,
  fetchVendorCompDetailsEpic,
  fetchVendorMiniLeagueDetailsEpic,
);
