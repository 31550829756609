import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {getViewedAnnouncements} from '../localStorage/LocalStorageSelectors';

export const getAnnouncements = (state: IAppState) =>
  state.serverApis.firestore.announcements.response;

export const getNextAnnouncementToShow = createSelector(
  getAnnouncements,
  getViewedAnnouncements,
  (announcements, announcementViewCounts) => {
    if (!announcements || announcements.length === 0) {
      return null;
    }
    if (announcementViewCounts === null) {
      announcementViewCounts = {};
    }
    return (
      announcements.find(
        (announcement) =>
          announcementViewCounts[announcement.id] === undefined ||
          announcementViewCounts[announcement.id] < announcement.displayCount,
      ) ?? null
    );
  },
);
