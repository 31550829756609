import React, {useEffect, useState} from 'react';

import log from '@chancer/common/lib/utils/Log';
import {IAuthComponentProps} from './Core/Auth/AuthComponentProps';

const loadHomePage = () =>
  import(/* webpackChunkName: "HomePage" */ './Page/HomePage');

const loadVendorPage = () =>
  import(/* webpackChunkName: "VendorPage" */ './Page/VendorPage');

const loadLandingPage = () =>
  import(/* webpackChunkName: "LandingPage" */ './Page/LandingPage');

const loadCompetitionPage = () =>
  import(/* webpackChunkName: "CompetitionPage" */ './Page/CompetitionPage');

const loadGroupsPage = () =>
  import(/* webpackChunkName: "GroupsPage" */ './Page/GroupsPage');

const loadYourGamesPage = () =>
  import(/* webpackChunkName: "YourGamesPage" */ './Page/YourGamesPage');

const loadNewsPage = () =>
  import(/* webpackChunkName: "NewsPage" */ './Page/NewsPage');

const loadChallengesPage = () =>
  import(/* webpackChunkName: "ChallengesPage" */ './Page/ChallengesPage');

const loadContentPage = () =>
  import(/* webpackChunkName: "ContentPage" */ './Page/ContentPage');

const loadAppsPage = () =>
  import(/* webpackChunkName: "AppsPage" */ './Page/AppsPage');

export const loadAccountScreen = () =>
  import(/* webpackChunkName: "AccountScreen" */ './Page/AccountScreen');

export const loadAuthComponent = () =>
  import(/* webpackChunkName: "AuthComponent" */ './Core/Auth/AuthComponent');

interface IEmptyProps {}

export const LazyHomePage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadHomePage}>
      {(Module: any) =>
        Module.HomePage ? <Module.HomePage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyVendorPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadVendorPage}>
      {(Module: any) =>
        Module.VendorPage ? <Module.VendorPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyLandingPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadLandingPage}>
      {(Module: any) =>
        Module.LandingPage ? <Module.LandingPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyCompetitionPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadCompetitionPage}>
      {(Module: any) =>
        Module.CompetitionPage ? <Module.CompetitionPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyGroupsPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadGroupsPage}>
      {(Module: any) =>
        Module.GroupsPage ? <Module.GroupsPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyYourGamesPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadYourGamesPage}>
      {(Module: any) =>
        Module.YourGamesPage ? <Module.YourGamesPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyNewsPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadNewsPage}>
      {(Module: any) =>
        Module.NewsPage ? <Module.NewsPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyChallengesPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadChallengesPage}>
      {(Module: any) =>
        Module.ChallengesPage ? <Module.ChallengesPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyContentPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadContentPage}>
      {(Module: any) =>
        Module.ContentPage ? <Module.ContentPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyAppsPage: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadAppsPage}>
      {(Module: any) =>
        Module.AppsPage ? <Module.AppsPage {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyAccountScreen: React.FC<IEmptyProps> = (props) => {
  return (
    <LazyLoader loadImport={loadAccountScreen}>
      {(Module: any) =>
        Module.AccountScreen ? <Module.AccountScreen {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyAuthComponent: React.FC<IAuthComponentProps> = (props) => {
  return (
    <LazyLoader loadImport={loadAuthComponent}>
      {(Module: any) =>
        Module.AuthComponent ? <Module.AuthComponent {...props} /> : null
      }
    </LazyLoader>
  );
};

interface IProps {
  children: (module: any) => JSX.Element | null;
  loadImport: () => Promise<any>;
}

const LazyLoader: React.FC<IProps> = (props) => {
  const [_module, setModule] = useState<any>(null);
  const [_error, setError] = useState<any>(null);
  const {loadImport} = props;

  useEffect(() => {
    loadImport()
      .then((mod: any) => {
        log.debug('mod', mod);
        setModule(
          // handle both es imports and cjs
          mod.default ? mod.default : mod,
        );
      })
      .catch((err) => {
        log.error('Failed to load module', err);
        setError(err);
      });
  }, [loadImport]);

  if (_module) {
    const t: string = typeof props.children;
    if (props.children && t === 'function') {
      return (props.children as (module: any) => JSX.Element)(_module);
    }
  }
  if (_error) {
    return (
      <div
        className="screen__container"
        style={{padding: '16px;', textAlign: 'center'}}>
        We have had an issue loading part of the game. <br />
        Please refresh your browser window
      </div>
    );
  }
  return <div className="screen__container" />;
};
