import {BuildEnvironment} from '../../interfaces/firestore/FirestoreInterfaces';
import {assertType} from '../../utils/TypeUtils';

export enum RemoteConfigBoolean {
  FALSE = 'false',
  TRUE = 'true',
}

interface IRemoteConfigBase {
  [key: string]: string | RemoteConfigBoolean;
}

export interface IRemoteConfig extends IRemoteConfigBase {
  min_app_version: string;
  feature_groups_enabled: RemoteConfigBoolean;
  feature_challenges_enabled: RemoteConfigBoolean;
  enter_comp_overlay: string;
  chat: string;
  news: string;
}

export const getDefaultRemoteConfig = (
  environment: BuildEnvironment,
): IRemoteConfig => ({
  min_app_version: '0.0.0',
  feature_groups_enabled: RemoteConfigBoolean.FALSE,
  feature_challenges_enabled: RemoteConfigBoolean.FALSE,
  enter_comp_overlay: JSON.stringify(
    assertType<IRemoteEnterCompOverlayConfig>({
      showPromoPercentage: 75,
      showMoreGamesPromoPercentage: 75,
    }),
  ),
  chat: JSON.stringify(
    assertType<IRemoteChatConfig>({
      maxLength: 280,
      maxMessages: 100,
      expireAfterMs: 7 * 24 * 60 * 60 * 1000,
    }),
  ),
  news: JSON.stringify(assertType<IRemoteNewsConfig>({tags: []})),
});

export interface IRemoteChatConfig {
  maxLength: number;
  maxMessages: number;
  expireAfterMs: number;
}

export interface IRemoteEnterCompOverlayConfig {
  showPromoPercentage: number;
  showMoreGamesPromoPercentage: number;
}

export interface IRemoteNewsConfig {
  tags: (string | {label: string; tags: string[]})[];
}
