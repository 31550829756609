import {createSelector} from 'reselect';

import {ICompSummaryAndEntry} from '../../state/model/CompetitionModel';
import {
  getCompsForVendor,
  getMiniLeagueLeaderboard,
  getVendors,
  getVendorsMap,
} from '../vendors/VendorSelectors';
import {
  getFilteredCompSummariesAndEntries,
  getResultedCompetitions,
  getUpcomingCompetitions,
} from './CompetitionSummarySelectors';

export const getUpcomingMiniLeagueCompetitions = createSelector(
  getUpcomingCompetitions,
  getVendorsMap,
  (competitions, vendors): ICompSummaryAndEntry[] =>
    competitions.filter(
      (c) => vendors.get(c.summary.vendor)?.isMiniLeague === true,
    ),
);

export const getResultedMiniLeagueCompetitions = createSelector(
  getResultedCompetitions,
  getVendorsMap,
  (competitions, vendors): ICompSummaryAndEntry[] =>
    competitions.filter(
      (c) => vendors.get(c.summary.vendor)?.isMiniLeague === true,
    ),
);

export const getVendorsMiniLeagueGamesMap = createSelector(
  getVendors,
  getFilteredCompSummariesAndEntries,
  (vendors, compSummaries) => {
    return vendors
      .filter((v) => v.isMiniLeague)
      .reduce((p, v, i) => {
        const vendorCompSummaries = getCompsForMiniLeague(
          compSummaries,
          v.vendor,
        );
        if (vendorCompSummaries.length > 0) {
          vendorCompSummaries.sort(
            (a, b) => a.summary.starts.toMillis() - b.summary.starts.toMillis(),
          );
          p.set(v.vendor, vendorCompSummaries);
        }
        return p;
      }, new Map<string, ICompSummaryAndEntry[]>());
  },
);

const getCompsForMiniLeague = (
  comps: ICompSummaryAndEntry[],
  vendor: string | undefined,
): ICompSummaryAndEntry[] => {
  if (vendor === undefined) {
    return comps;
  }
  const vendorComps = getCompsForVendor(comps, vendor);
  if (vendorComps.length === 0) {
    return vendorComps;
  }
  for (const comp of vendorComps) {
    try {
      const miniLeagueLeaderboard = getMiniLeagueLeaderboard(comp.summary);
      return vendorComps.filter((comp) =>
        comp.summary.leaderboards.some(
          (l) => l.id === miniLeagueLeaderboard.id,
        ),
      );
    } catch {
      // Ignore
    }
  }
  console.log('No mini game leaderboard found for vendor', vendor);
  return [];
};
